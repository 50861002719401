import { useState, useRef } from "react";

import PageHeader from "../../components/PageHeader";
import Brochure1 from "../../assets/pdf/ACESUHAM.pdf";

export default function ACESUHAM() {
    const headRef = useRef();

    return (
        <div>
            <PageHeader
                currentPage={"Advanced Centre for Enabling Sustainable Health care advancement (ACESUHAM)"}
                travelPages={[
                    "Centres",
                    "Advanced Centre for Enabling Sustainable Health care advancement (ACESUHAM)",
                ]}
            />
            <div ref={headRef}></div>

            <div className="container-fluid my-5 px-md-5">
                <div className="row justify-content-center">
                    <div className="col-md-8 network">
                        {/* <h2>Advanced Centre for Enabling Sustainable Health care advancement (ACESUHAM)</h2> */}
                        <h6 className="mt-0">Overview</h6>
                        <p>As part of deepening health interventions and TDA has set up an Advanced Centre for Enabling Sustainable Healthcare Advancement (ACE SUHAM) which will be attempting to incorporate the lessons learnt through health intervention for wider learning and sharing. The 'Sustainable Healthcare Advancement (SUHAM) Trust' will take the anchoring role to facilitate the actions of this centre. The ACE SUHAM, the specialized centre at the Academy would endeavor to foster the process of advancing the practice of community health, Sanitation, Safe water and medical care through appropriate knowledge management systems and processes that include research, documentation, publication, training, education, policy advocacy etc.
                        </p>
                        <h6>Vision </h6>
                        <ul>
                            <li>
                                <p> ACE SUHAM would strive to document the experiences and best practices to disseminate and build the system for equitable healthcare interventions. ACE SUHAM will be guided by the following vision;
                                </p>
                            </li>
                            <li>  <p>ACE SUHAM will be a premier knowledge centre for bringing solutions for healthcare needs of disadvantage community.  </p></li>
                            <li>  <p>ACE SUHAM will complement theory and practice of health and medical care through research, capacity building, collaboration and dissemination of knowledge.  </p></li>
                            <li>  <p>ACE SUHAM will create an enabling environment through policy advocacy for effective access to health, WASH and medical services to poor.   </p></li>
                        </ul>
                        <h6>Mission </h6>
                        <p>In tune with the vision and purpose of the Academy, ACE SUHAM would contribute to enabling the Academy in becoming the 'Centre of Excellence' in development education, training and research. ACE SUHAM would work with the mission to 'Promote and advancing healthcare for the poor by disseminating the knowledge and capacity for professionals and governance. </p>
                    </div>
                    <div className="col-md-3 ">
                        <div className=" centre-broucher">
                            <h6 className="mb-4">Broucher</h6>
                            <div className="d-inline-block">
                                <a className="outline-btn" href="https://thedhanacademy.wordpress.com/archives/" target="_blank">
                                    Brochure
                                </a>
                                <a className="outline-btn  mt-3" href="https://thedhanacademy.wordpress.com/updates/" target="_blank">
                                    Updates
                                </a>
                                <a className="outline-btn mt-3" href="https://thedhanacademy.wordpress.com/archives/" target="_blank">
                                    Publications
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
