import { useState, useRef } from "react";

import PageHeader from "../../components/PageHeader";
import Brochure1 from "../../assets/pdf/Brochure1.pdf";

export default function MRC() {
    const headRef = useRef();

    return (
        <div>
            <PageHeader
                currentPage={" Media Resource Centre"}
                travelPages={[
                    "Centres",
                    " Media Resource Centre",
                ]}
            />
            <div ref={headRef}></div>

            <div className="container-fluid my-5 px-md-5">
                <div className="row justify-content-center">
                    <div className="col-md-8 network">
                        {/* <h2>Media Resource Centre</h2> */}
                        <h6 className="mt-0">Overview</h6>
                        <ul>
                            <li><p>Building the capacity of community media professionals is essential for ensuring that they have the skills and knowledge they need to produce high-quality programming that is relevant to their audiences.</p></li>
                            <li><p>Conducting research ( micro studies) on the reach and impact of community media is important for understanding its potential to contribute to social change.</p></li>
                            <li><p>Providing guidance and facilitation for resource mobilization can help community media organizations secure the funding they need to operate and grow.                           </p></li>
                            <li><p>Streamlining the use of social media ( facebook,  Instagram, You tube, etc) for development activities can help community media organizations reach a wider audience and amplify their message.</p></li>
                            <li><p>Policy advocacy for Development  / Community Media</p></li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <div className=" centre-broucher">
                            <h6 className="mb-4">Broucher</h6>
                            <div className="d-inline-block">
                                <a className="outline-btn" href="https://thedhanacademy.wordpress.com/archives/" target="_blank">
                                    Brochure
                                </a>
                                <a className="outline-btn  mt-3" href="https://thedhanacademy.wordpress.com/updates/" target="_blank">
                                    Updates
                                </a>
                                <a className="outline-btn mt-3" href="https://thedhanacademy.wordpress.com/archives/" target="_blank">
                                    Publications
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
