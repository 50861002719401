import { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

// import Slider1 from "../assets/img/slider/slide-01.jpg";
// import Slider2 from "../assets/img/slider/slide-02.jpg";
// import Slider3 from "../assets/img/slider/slide-03.jpg";

import HomeRepository from "../repositories/HomeRepository";

export default function HomeSlider() {
  const [slides, setSlides] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let res = await HomeRepository.getBannerSlides("banner");
    let data = [];
    res?.data?.length > 0 &&
      res?.data.map((d, i) => {
        data.push(d?.image_url);
      });
    setSlides(data);
  };

  return (
    <div className="slider-wrapper">
      <Slider {...settings}>
        {slides.length > 0 &&
          slides.map((item, i) => {
            return (
              <div key={i}>
                <div
                  className="slider-items"
                  style={{
                    backgroundImage: `url(data:image/png;base64,${item})`,
                  }}
                >
                  <div className="content">
                    <h1>
                      The <span>DHAN</span> Academy
                    </h1>
                    <h6>
                      Induct and Groom Young Graduates for Development Actions.
                    </h6>
                    <button>  <Link
                      to={"/contact"}
                    >Contact us</Link>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
}
