import { useState, useRef } from "react";

import PageHeader from "../../components/PageHeader";
import Brochure1 from "../../assets/pdf/ACEWE.pdf";

export default function ACEWE() {
    const headRef = useRef();

    return (
        <div>
            <PageHeader
                currentPage={" Advanced Centre for Enabling Women Enterprises (ACEWE)"}
                travelPages={[
                    "Centres",
                    " Advanced Centre for Enabling Women Enterprises (ACEWE)",
                ]}
            />
            <div ref={headRef}></div>

            <div className="container-fluid my-5 px-md-5">
                <div className="row justify-content-center">
                    <div className="col-md-8 network">
                        {/* <h2>Advanced Centre for Enabling Women Enterprises (ACEWE)</h2> */}
                        <h6 className="mt-0">Overview</h6>
                        <p>Advanced Centre for Enabling Women Empowerment (ACEWE) is a specialized centre
                            being established by DHAN Foundation, housed at the academy with anchorship of
                            Kalanjiam Foundation to build the capacity to bridge the gap in women empowerment
                            process and create opinion makers on women empowerment. The Centre would focus on enhancing
                            knowledge and practice on women empowerment by working closely with women based people organisations,
                            NGOs working for women empowerment, research and academic institutions which have discipline on gender
                            and women empowerment and funding agencies. ACEWE is a multi-disciplinary in nature which would invite f
                            aculty from difference fields of expertise to contribute in knowledge building and management process
                            through a set of defined activities. The broad purpose are Creating and managing knowledge on Women
                            Empowerment from DHAN’s field practice and outside experiences, Developing community based processes,
                            tools and methods for institutionalizing Women Empowerment, Mainstreaming Women Empowerment goal and
                            indicators into development programmes/activities, and Integrating Sustainable Development Goals for
                            facilitating global perspective on Women Empowerment. </p>
                    </div>
                    <div className="col-md-3 ">
                        <div className=" centre-broucher">
                            <h6 className="mb-4">Broucher</h6>
                            <div className="d-inline-block">
                                <a className="outline-btn" href="https://thedhanacademy.wordpress.com/archives/" target="_blank">
                                    Brochure
                                </a>
                                <a className="outline-btn  mt-3" href="https://thedhanacademy.wordpress.com/updates/" target="_blank">
                                    Updates
                                </a>
                                <a className="outline-btn mt-3" href="https://thedhanacademy.wordpress.com/archives/" target="_blank">
                                    Publications
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
