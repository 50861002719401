import { useState, useRef } from "react";


export default function Recreation() {
    const headRef = useRef();

    return (
        <div>
            <div ref={headRef}></div>

            <div className="prayer">
                <div className="container-fluid my-5 px-md-5 py-5">
                    <div className="row justify-content-center">
                        <div className="col-md-7 align-self-center">
                            <h2 className="text-center">Recreation & Shramdhan</h2>
                            <h6>Recreation</h6>
                            <p>Students can watch TV during leisure time. Whenever students want to view movies during Sundays or holidays, they can screen movies with the permission of the Warden. Facilities are available for lawn tennis, volleyball, badminton, and cricket. Students can make use of those grounds and play in evening time. Besides these, carrom, chess, and other indoor games are available in the hostel.</p>
                            <h6>Shramdhan</h6>
                            <p>Shramdhan is a useful way to make leisure time more productive. Students perform shramdhan individually or in groups, helping to maintain students’ living areas, maintain the campus premises and playgrounds, planting saplings, and watering the hostel orchard. They also encouraged to do social work in the nearby villages like cleaning temples, blood camps, health camps, etc</p>
                        </div>
                        <div className="col-md-5">
                            <iframe width="100%"
                                height="300"
                                className="mb-3 mt-3"
                                src="https://www.youtube.com/embed/urWxaueM858"
                                title="Recreation and Shramdaan" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    );
}
