import { useState, useRef } from "react";

import PageHeader from "../components/PageHeader";

export default function Network() {
    const headRef = useRef();


    return (
        <div>
            <PageHeader
                currentPage={"Research"}
                travelPages={["Home", "Research"]}
            />
            <div ref={headRef}></div>

            <div className="container-fluid my-5 px-md-5">
                <div className="row justify-content-center">
                    <div className="col-md-10 network">
                        <h2>Research</h2>
                        <p>
                            The DHAN Academy in its endeavour to position the Education institution as a Centre of excellence has been constantly involved in improving the Quality of its services by bringing necessary depth and breath. Along with teaching and training through PDM and DMPs respectively, it also recognises Research as yet another core area which alone can bring life and soul to the existing Knowledge base of the academy.
                            <br />
                            Research in Academy confines to build development Knowledge and to enable the world of practice. The Academy could contribute to the pool of ‘Development Knowledge’ by identifying the critical gaps. Present development knowledge is characterised by imbalance. Some aspects have received enormous attention. Research on Macro aspects has largely predominated. Micro researches/scenario has been neglected. Nevertheless, micro researches are very important to enable practice, as the development scenario is contextual.
                            <br />
                            Also, there is another level of under emphasis. Research on ‘development issues’ are in abundance.But, Research on strategies and approaches (or about interventions) are sparse. The body of Knowledge bristles with economic perspectives of development phenomena. Social and cultural dimensions remain largely untouched. The implications of various developments are also underrepresented.
                            <br />
                            The relationships between macro and micro realities (especially institutions) are another lacuna in the body of development Knowledge. They have been perceived as dichotomies and the integration between them is largely ignored. As a result, the strategies or approaches evolved have been only partially successful in practice. The Academy would encourage research keeping in mind the above listed areas of critical gaps.
                        </p>
                        <h6>Purpose</h6>
                        <p> The Academy would take up research projects/programme keeping in view of the following objectives.</p>
                        <ul>
                            <li>
                                To facilitate Knowledge creation and dissemination so as to contribute to the Development sector
                            </li>
                            <li>
                                To advance / enable practice so as to have immediate utility in direct action
                            </li>
                            <li>
                                To bring enhancement in the PDM curriculum and DMPs offered
                            </li>
                            <li>
                                To help in faculty development by widening the perspectives, skills and knowledge of the individuals so as to enhance their teaching
                            </li>
                        </ul>
                        <h6>Research Projects completed</h6>
                        <ul>
                            <li>
                                District Human Development Report, Sivagangai and Tiruvannamalai Dist, Tamilnadu
                            </li>
                            <li>
                                Contract Farming
                            </li>
                            <li>
                                Micro-finance and health
                            </li>
                        </ul>
                        <h2>About Centre for Research</h2>
                        <p>Centre for Research (CR) facilitates field-based research on issues pertaining to the sector and the organization. It undertakes sectoral research on microfinance, tank-fed agriculture and other rural and urban development issues in addition to taking up impact studies of DHAN projects in order to build up a knowledge base for the sector. The Centre facilitates the professionals working at the grassroots to take up micro-studies and action researches on various issues. The Centre collaborates with academician, research institutions and universities on common research interests to exchange expertise and to bring academic rigor to the research outcomes.</p>
                        <h6>Projects Done:</h6>
                        <ul>
                            <li>
                                A study on “Understanding Vulnerabilities of rice cultivation among coastal Agricultural Communities to Frequent Disasters and Coping Mechanisms through Resilience” - Self Assessment by Community in Balasore (in progress).                            </li>
                            <li>
                                District Human Development Report (DHDR) for Madurai, Ramnad and Tiruvannamalai prepared DHDR and submitted to Tamil Nadu State planning commission.                            </li>
                            <li>
                                Impact Evaluation Study on National Agriculture Development Programme (NADP) / Rashtriya Krishi Vikas Yojana (RKVY) to assess the impact of projects implemented in the year 2013-14.                            </li>
                            <li>
                                Study on Community Capital (Savings of poor with and through Self-Help Groups)-NABARD- The study carried out in India in four regions of East, West, North and South to understand the regional imbalances and best practices among the members on SHG savings                            </li>
                            <li>
                                A study to explore “Best Practices to address the Gaps in Disaster Management” in four coastal blocks of Balasore district, Odisha. This study was supported by AXIS Bank Foundation collaboration with CALL programme, ACEDRR and Balasore CALL region.                            </li>
                            <li>
                                “A micro study on defunct groups” - To assess the internal system on defunct groups management. In other words, to find out the efficiency of Programme management in handling Defunct Groups                            </li>
                            <li>
                                “Preparation of Perspective plan” for 7 backward blocks in Ramanathapuram district and 4 backward blocks in Madurai district under State Balanced Growth Fund (SBGF) by Tamil Nadu State Planning Commission. Study was conducted from 2013-14 to 2015-16.
                            </li>
                            <li>
                                Scoping study of Porridge Pushcart Vendors/ Recipes demonstration in corporate and Educational institutions was conducted (2013-14)
                            </li>
                            <li>
                                Country Research – “Poverty Reduction and Women Economic Leadership: Roles, Potentials and Challenges of Social Enterprises in India” Submitted to ISEA, Philippines                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div >
    );
}
