import React from "react";
import { Link } from "react-router-dom";

import Logo from "../../assets/img/logo-with-text.png";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container pt-md-5 pt-4 pb-md-4 pb-2">
        {/* <img className="logo" src={Logo} alt="The DHAN Academy" /> */}
        <h3 className="footer-title">The DHAN Academy (TDA)</h3>
        <div className="row">
          <div className="col-sm-4 col-md-4 mb-2 mb-md-0">
            <div className="widget dark">
              <h5 className="widget-title mb-10">Address</h5>
              <p>
                The DHAN Academy,
                <br />
                T. Malaipatti, Thenkarai,
                <br />
                Mullipallam (PO), Melakkal (Via),
                <br />
                Madurai - 625207.
              </p>
            </div>
          </div>
          <div className="col-sm-4 col-md-4 mb-2 mb-md-0">
            <div className="widget dark">
              <h5 className="widget-title mb-10">Contact Us</h5>
              <p className="footer-link">
                <a href="mailto:tda@dhan.org">
                  <i className="far fa-envelope"></i> tda@dhan.org
                </a>
              </p>
              <p className="footer-link">
                <a href="mailto:admissions.tda@dhan.org">
                  <i className="far fa-envelope"></i>
                  admissions.tda@dhan.org
                </a>
              </p>
              <p className="footer-link">
                <a href="mailto:thedhanacademymedia@gmail.com">
                  <i className="far fa-envelope"></i>
                  thedhanacademymedia@gmail.com
                </a>
              </p>
              <p className="footer-link">
                <a href="mailto:thedhanacademymedia@gmail.com">
                  <i className="far fa-envelope"></i>
                  thedhanacademy@gmail.com
                </a>
              </p>
              <p className="footer-link">
                <a href="tel:+91 4543 299 989">
                  <i className="far fa-phone-alt"></i>+91 4543 299 989
                </a>
                <br />
                <a className="mt-2">
                  <i className="far fa-phone-alt"></i>+91 96775 83405
                </a>
              </p>
            </div>
          </div>
          <div className="col-sm-4 col-md-4 mb-2 mb-md-0">
            <div className="widget dark">
              <h5 className="widget-title mb-10">Follow Us</h5>
              <ul className="social-icons">
                <li>
                  <a
                    href="https://www.facebook.com/thedhanacademy"
                    data-bg-color="#3B5998"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-square"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCll4SkVeqwnKoi5b9n2X4pA"
                    data-bg-color="#C22E2A"
                    target="_blank"
                  >
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/dhanacademy?lipi=urn%3Ali%3Apage%3Ad_flagship3_company_admin%3BuxpfO6OfTjOtcYZoWy82GA%3D%3D"
                    data-bg-color="#3B5998"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/the_dhan_academy?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                    data-bg-color="#3B5998"
                    target="_blank"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <p className="font-14 text-black-777 m-0 mb-10">
                Copyright &copy;
                <script>document.write(new Date().getFullYear())</script> The
                DHAN Academy. All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer >
  );
}
