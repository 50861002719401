import { useState, useRef } from "react";

import PageHeader from "../../components/PageHeader";
import Brochure1 from "../../assets/pdf/ACEDRR.pdf";
import { Link } from 'react-router-dom';

export default function ACEDRR() {
    const headRef = useRef();

    return (
        <div>
            <PageHeader
                currentPage={" Advanced Centre for Enabling Disaster Risk Reduction (ACEDRR)"}
                travelPages={[
                    "Centres",
                    " Advanced Centre for Enabling Disaster Risk Reduction (ACEDRR)",
                ]}
            />
            <div ref={headRef}></div>

            <div className="container-fluid my-5 px-md-5">
                <div className="row justify-content-center">
                    <div className="col-md-8 network">
                        {/* <h2>Advanced Centre for Enabling Disaster Risk Reduction (ACEDRR)</h2> */}
                        <h6 className="mt-0">Overview</h6>
                        <p>The Advanced Centre for Enabling Disaster Risk Reduction (ACEDRR) is a specialisedcentre established at the The DHAN Academy by DHAN Foundation with the support of Oxfam America, an international relief and development organization and affiliate of Oxfam International that works on creating lasting solutions to poverty, hunger, and injustice. The ACEDRR aims at enhancing the knowledge and practice on disaster risk reduction (DRR) by working with peoples' organisations, non-government and government organizations, technical institutes, research and academic institutes and funding agencies. </p>
                    </div>
                    <div className="col-md-3">
                        <div className=" centre-broucher">
                            <h6 className="mb-4">Broucher</h6>
                            <div className="d-inline-block">
                                <a className="outline-btn" href="https://thedhanacademy.wordpress.com/archives/" target="_blank">
                                    Brochure
                                </a>
                                <a className="outline-btn  mt-3" href="https://thedhanacademy.wordpress.com/updates/" target="_blank">
                                    Updates
                                </a>
                                <a className="outline-btn mt-3" href="https://thedhanacademy.wordpress.com/archives/" target="_blank">
                                    Publications
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
