import { useState, useRef } from "react";

import PageHeader from "../../components/PageHeader";
import { Link } from 'react-router-dom';

export default function Network() {
    const headRef = useRef();

    return (
        <div>
            <PageHeader
                currentPage={"Land Based Water Pollution"}
                travelPages={[
                    "Online Courses",
                    "Land Based Water Pollution",
                ]}
            />
            <div ref={headRef}></div>

            <div className="container-fluid my-5 px-md-5">
                <div className="row justify-content-center">
                    <div className="col-md-10 network">
                        <h2>Land Based Water Pollution</h2>
                        <h6>Course Description</h6>
                        <p>An elective to be taught as part of environmental science/engineering; agricultural science/engineering undergraduate course. This course addresses the problem of land-based water pollution mainly arising from non-point source pollution from agriculture and poor sanitation practices. It will highlight the sources and impacts linked to land-based water pollution and identify ways in which pollution control maybe implemented for sustainable results. Gaps in regulatory mechanisms and management practices will be highlighted to build the case for an integrated approach to planning and implementing solutions. Examples of mitigation measures will also be provided to help develop an understanding of approaches/concepts that maybe successfully implemented. </p>
                        <h6>Course Objective</h6>
                        <p>It is aimed that on completion of the course the students would be able to identify the main sources of land-based water pollution, its intensity and impact. Based on the understanding of the problem, students are able to develop solution options and define the elements essential for its sustainability. <br />
                            The course on land-based water pollution and its management provides students with comprehensive knowledge and skills related to various land-based pollution problems, fundamentals of the water cycle, hydrology and water use trends and categories necessary for preventing land-based pollution and its control. Planning and implementation of mitigation measures along with its theoretical bases will be discussed to provide students with an overview of workable solutions, its applicability and usefulness along with the limitations linked with it. It also introduces the political and institutional context of water pollution.
                        </p>
                        <h6> Duration</h6>
                        <p>This is a four-module course that is designed to be taught for a duration of hours over a period of one month.</p>
                        <h6>Target Group</h6>
                        <p>Agricultural/Environmental / Water Resource Management students or professionals working on Water Resource Management</p>
                        <h6>Level</h6>
                        <p>Beginner / Basicß</p>
                        <h6>Learning Outcomes</h6>
                        <p> At the end of this topic, the students will be able to:</p>
                        <ul>
                            <li>
                                Define various water pollution problems especially the land-based water pollution;
                            </li>
                            <li>
                                Describe fundamentals of the water cycle, hydrology and water use trends and resulting pollution issues with a special focus on land-based pollution
                            </li>
                            <li>
                                Discuss the planning process, its implementation activities and its various theoretical bases;
                            </li>
                            <li>
                                Define the political and institutional context of land-based water pollution mitigation;
                            </li>
                            <li>
                                Distinguish the various principles and approaches for managing land-based water pollution and its usefulness;
                            </li>
                        </ul>
                        <h6>Mode of Delivery</h6>
                        <p>This course is designed to be delivered as:<br />
                            This course may be provided in a fully online mode facilitated within a Virtual Learning Environment (VLE) – Moodle Platform and encouraging participant interaction, engagement and collaboration.
                        </p>
                        <h6>Assessment Methods</h6>
                        <table>
                            <tr>
                                <td>Continuous assessment (activities, assignments, quizzes)</td>
                                <td>20%</td>
                            </tr>
                            <tr>
                                <td>Issues paper </td>
                                <td>30%</td>
                            </tr>
                            <tr>
                                <td>Final exam </td>
                                <td>50%</td>
                            </tr>
                        </table>
                        <h6>Instruction methodology</h6>
                        <p>The instructional methodology will follow the Tell me, Show me and Let me try it approach:</p>
                        <p>Tell me</p>
                        <ul>
                            <li>
                                Lectures on concepts, principles, practices and approaches of irrigation agronomy.
                            </li>
                        </ul>
                        <p>Show me</p>
                        <ul>
                            <li>Case studies, instructional videos, maps, etc. </li>
                            <li>Field excursion and discussion. (if possible) </li>
                        </ul>
                        <p>Let me try</p>
                        The students will be given the opportunity to apply the knowledge and skill they acquired from the tell me and show me sessions through:

                        <ul><li>Assignments;</li>
                            <li>Group work;</li>
                            <li>Projects and case studies;</li>
                            <li>Report writing and presentations.</li>
                        </ul>
                        <h6>Time</h6>
                        <p>Total of One hundred and seven hours - (Including lectures, seminars / workshops / independent study)</p>
                        <div className="text-center d-flex">
                            <Link className="outline-btn" target="_blank" to="https://thedhanacademy.wordpress.com/updates">Updates</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
