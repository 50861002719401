import React, { useState } from "react";

import PageHeader from "../components/PageHeader";

import Pdm22std1 from "../assets/img/students/PDM22/pdm22-student1.png"
import Pdm22std2 from "../assets/img/students/PDM22/pdm22-student2.png"
import Pdm22std3 from "../assets/img/students/PDM22/pdm22-student3.png"
import Pdm22std4 from "../assets/img/students/PDM22/pdm22-student4.png"
import Pdm22std5 from "../assets/img/students/PDM22/pdm22-student5.png"
import Pdm22std6 from "../assets/img/students/PDM22/pdm22-student6.png"
import Pdm22std7 from "../assets/img/students/PDM22/pdm22-student7.png"

import Pim01std1 from "../assets/img/students/PIM01/pim01-student1.png"
import Pim01std2 from "../assets/img/students/PIM01/pim01-student2.png"
import Pim01std3 from "../assets/img/students/PIM01/pim01-student3.png"
import Pim01std4 from "../assets/img/students/PIM01/pim01-student4.png"
import Pim01std5 from "../assets/img/students/PIM01/pim01-student5.png"
import Pim01std6 from "../assets/img/students/PIM01/pim01-student6.png"
import Pim01std7 from "../assets/img/students/PIM01/pim01-student7.png"
import Pim01std8 from "../assets/img/students/PIM01/pim01-student8.png"
import Pim01std9 from "../assets/img/students/PIM01/pim01-student9.png"
import Pim01std10 from "../assets/img/students/PIM01/pim01-student10.png"

import Pdm23std1 from "../assets/img/students/pdm23/pdm23-student1.png"
import Pdm23std2 from "../assets/img/students/pdm23/pdm23-student2.png"
import Pdm23std3 from "../assets/img/students/pdm23/pdm23-student3.png"
import Pdm23std4 from "../assets/img/students/pdm23/pdm23-student4.png"
import Pdm23std5 from "../assets/img/students/pdm23/pdm23-student5.png"
import Pdm23std6 from "../assets/img/students/pdm23/pdm23-student6.png"
import Pdm23std7 from "../assets/img/students/pdm23/pdm23-student7.png"

import Pim02std1 from "../assets/img/students/pim02/pim02-student1.png";
import Pim02std2 from "../assets/img/students/pim02/pim02-student2.png";
import Pim02std3 from "../assets/img/students/pim02/pim02-student3.png";
import Pim02std4 from "../assets/img/students/pim02/pim02-student4.png";
import Pim02std5 from "../assets/img/students/pim02/pim02-student5.png";

export default function Students() {
  const [activeTab, setActiveTab] = useState("PDM23");

  return (
    <div>
      <PageHeader
        currentPage={"Students"}
        travelPages={["About Us", "People", "Students"]}
      />
      <div className="container pt-md-5 pt-4">
        <h1 className="title-text mb-md-5 mb-4">
          Student <span>Profile</span>
        </h1>
        <div className="tab-wrapper mb-4">
          <button
            onClick={() => setActiveTab("PDM23")}
            className={`tab ${activeTab == "PDM23" ? "active" : ""}`}
          >
            PDM23
          </button>
          <button
            onClick={() => setActiveTab("PIM02")}
            className={`tab ${activeTab == "PIM02" ? "active" : ""}`}
          >
            PIM02
          </button>
          <button
            onClick={() => setActiveTab("PDM22")}
            className={`tab ${activeTab == "PDM22" ? "active" : ""}`}
          >
            PDM22
          </button>
          <button
            onClick={() => setActiveTab("PIM01")}
            className={`tab ${activeTab == "PIM01" ? "active" : ""}`}
          >
            PIM01
          </button>
        </div>
        {activeTab == "PDM23" && (
          <div className="row pb-5">
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pdm23std1} alt="ABHIJIT DAS" />
                <div className="card-text">
                  <h6>ABHIJIT DAS</h6>
                  <p>B. Sc. Agriculture</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>English, Hindi and Bengali</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">West Bengal</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pdm23std2} alt="ADITI NEHA" />
                <div className="card-text">
                  <h6>ADITI NEHA</h6>
                  <p>B. Sc. Zoology (Hons)</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>English and Hindi</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">Jharkhand</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pdm23std3} alt="PATIL AMIT SADHU" />
                <div className="card-text">
                  <h6>PATIL AMIT SADHU</h6>
                  <p>B. A. English</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>English, Hindi and Marathi</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">Maharastra</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pdm23std4} alt="PIYALI PRAMANIK" />
                <div className="card-text">
                  <h6>PIYALI PRAMANIK</h6>
                  <p>B. Sc. Botany</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>English, Hindi, Bengali</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">West Bengal</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pdm23std5} alt="SOUMEN MUKHERJEE" />
                <div className="card-text">
                  <h6>SOUMEN MUKHERJEE</h6>
                  <p>B. Voc Sustainable Agriculture (Hons)</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>English, Hindi and Bengali</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">West Bengal </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pdm23std6} alt="SOURAV MANDAL" />
                <div className="card-text">
                  <h6>SOURAV MANDAL</h6>
                  <p>B.Sc. in Bio Science</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>English, Bengali and Hindi </p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">West Bengal</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pdm23std7} alt="WAGH YOGESH SUKHADEO" />
                <div className="card-text">
                  <h6>WAGH YOGESH SUKHADEO</h6>
                  <p>M.A Journalism and Mass Communication</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>English, Hindi and Marathi</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">Maharastra</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        )}
        {activeTab == "PIM02" && (
          <div className="row pb-5">
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pim02std1} alt="PREETHI A R" />
                <div className="card-text">
                  <h6>PREETHI A R</h6>
                  <p>Bachelors in Social Work</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>Kannada, English and Tulu</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">Karnataka</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pim02std2} alt="RAHUL SEN" />
                <div className="card-text">
                  <h6>RAHUL SEN</h6>
                  <p>B. Sc. Agriculture (Hons)</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>English, Hindi and Bengali</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">West Bengal</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pim02std3} alt="SHRINIVAS GOUDA" />
                <div className="card-text">
                  <h6>SHRINIVAS GOUDA</h6>
                  <p>M. Com.</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>Kannada, English and Hindi</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">Karnataka</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pim02std4} alt="SUSHMITHA" />
                <div className="card-text">
                  <h6>SUSHMITHA</h6>
                  <p>Bachelors in Social Work</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>Kannada and English</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">Karnataka</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pim02std5} alt="TANUJA SHANKAR GOUDA" />
                <div className="card-text">
                  <h6>TANUJA SHANKAR GOUDA</h6>
                  <p>B. Com.</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>Kannada, English and Hindi</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">Kartnataka</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        )}
        {activeTab == "PDM22" && (
          <div className="row pb-5">
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pdm22std1} alt="MOHINI NISHA" />
                <div className="card-text">
                  <h6>MOHINI NISHA</h6>
                  <p>Bachelor of Agriculture and Rural Management</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>Hindi, Bengali, Nagpuri and English</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">Jharkhand U</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pdm22std2} alt="PRIYADARSHINI NATH" />
                <div className="card-text">
                  <h6>PRIYADARSHINI NATH</h6>
                  <p>Post Graduation in Sociology</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>Hindi, Odia, and English</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">Odisha</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pdm22std3} alt="RAJESH MONDAL" />
                <div className="card-text">
                  <h6>RAJESH MONDAL</h6>
                  <p>Bachelor of Agriculture</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>Hindi, Bengali, and English</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">West Bengal</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pdm22std4} alt="RAKESH KUMAR DEY" />
                <div className="card-text">
                  <h6>RAKESH KUMAR DEY</h6>
                  <p>Bachelor of Agriculture</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>Hindi, Bengali, and English</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">West Bengal</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pdm22std5} alt="SAJAL" />
                <div className="card-text">
                  <h6>SAJAL</h6>
                  <p>Bachelor of Journalism and Mass Communication</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>Hindi, Haryani and Englishh</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">Haryana</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pdm22std6} alt="SUDHA PANDA" />
                <div className="card-text">
                  <h6>SUDHA PANDA</h6>
                  <p>B.Sc Zoology</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>Bengali, Hindi and English</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">West Bengal</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pdm22std7} alt="SUNITHA. A" />
                <div className="card-text">
                  <h6>SUNITHA. A</h6>
                  <p>Bachelor of commerce</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>Kannada and English</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">Karnataka</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab == "PIM01" && (
          <div className="row pb-5">
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pim01std1} alt="ANANTH M" />
                <div className="card-text">
                  <h6>ANANTH M</h6>
                  <p>B.E. CIVIL ENGINEERING</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>Tamil and English</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">TAMIL NADU</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pim01std2} alt="BALAJI G" />
                <div className="card-text">
                  <h6>BALAJI G</h6>
                  <p>Bachelor of Computer Science</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>Tamil and English</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">TAMIL NADUR</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pim01std3} alt="GAYATHRI L" />
                <div className="card-text">
                  <h6>GAYATHRI L</h6>
                  <p>Bachelor in Botany</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>Tamil, English and Hindi</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">Tamil Nadu</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pim01std4} alt="MANIKANDAN S" />
                <div className="card-text">
                  <h6>MANIKANDAN S</h6>
                  <p>Bachelor of Commerce</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>Tamil, English and Hindi</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">Tamil Nadu</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pim01std5} alt="NAVEENA SRI G" />
                <div className="card-text">
                  <h6>NAVEENA SRI GS</h6>
                  <p>Bachelor of Business Administration, Master of Commerce</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>Tamil and English</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">Tamil Nadu</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pim01std6} alt="PRIYA" />
                <div className="card-text">
                  <h6>PRIYA</h6>
                  <p>B.Tech agricultural engineering</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>Kannada, English and Hindi</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">Karnataka</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pim01std7} alt="RASHMI RANJAN KHARA" />
                <div className="card-text">
                  <h6>RASHMI RANJAN KHARA</h6>
                  <p>Bachelor of Mathematics</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>Odia, Hindi and English</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">Odisha</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pim01std8} alt="THATCHAYANI V" />
                <div className="card-text">
                  <h6>THATCHAYANI V</h6>
                  <p>Master of Geologys</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>Tamil, English and Telugu</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">Tamil Nadu </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pim01std9} alt="VANCHINATHA BHARATHI.P" />
                <div className="card-text">
                  <h6>VANCHINATHA BHARATHI.P</h6>
                  <p>Master of Geologys</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>Tamil, English and Telugu</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">Tamil Nadu </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="student-card">
                <img src={Pim01std10} alt="VIJAYA GANESH" />
                <div className="card-text">
                  <h6>VIJAYA GANESH</h6>
                  <p>B.Sc (Maths)</p>
                  <div className="list-item">
                    <span>
                      <i className="far fa-language"></i> Languages:
                    </span>
                    <p>Tamil, English and Telugu</p>
                    <span>
                      <i className="far fa-map-marker-alt"></i> State:
                    </span>
                    <p className="mb-0">Tamil Nadu </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        )}
      </div>
    </div>
  );
}
