import { useState, useRef } from "react";


export default function Yoga() {
    const headRef = useRef();

    return (
        <div>
            <div ref={headRef}></div>

            <div className="prayer">
                <div className="container-fluid my-5 px-md-5 py-5">
                    <div className="row justify-content-center">
                        <div className="col-md-7 align-self-center">
                            <h2 className="text-center">Yoga</h2>
                            <p>Yoga is also one of the key practice followed in the academy. It also included in each term as a course. Students had to do yoga three days in a week with a faculty and remaining days students are asked to do by themselves. Students will be evaluated based on punctuality, attendance, practice of various activities and exam. The precise choice of components and weights will be allocated by the faculty concerned.</p>
                            <div className="text-center">
                                <iframe width="90%"
                                    height="300"
                                    className="mb-3 mt-3" src="https://www.youtube.com/embed/lYDH_Md_oG0"
                                    title="Yoga"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen></iframe>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    );
}
