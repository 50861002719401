import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import VisionImg from "../assets/img/tda-vision.png";
import GenesisImg from "../assets/img/tda-genesis.png";
import BoardImg from "../assets/img/tda-board.png";

import HomeSlider from "../components/HomeSlider";
import Marquee from "../components/Marquee";
import Announcement from "../components/Announcement";
import HomeEvents from "../components/HomeEvents";

import HomeRepository from "../repositories/HomeRepository";

export default function Home() {
  const [wayOfLife, setWayOfLife] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let res = await HomeRepository.getWayOfLife();
    let data = [];
    if (res.data) {
      res?.data?.length > 0 &&
        res?.data.map((d, i) => {
          data.push(d?.video_url);
        });
    }
    setWayOfLife(data);
  };

  return (
    <div>
      <HomeSlider />
      <Marquee />
      <section>
        <div className="container">
          <div className="d-flex gap-4 mb-4 justify-content-center">
            <Link
              className="outline-btn"
              to={"/admission"}
            >
              <i class="fas fa-user-plus"></i>  Admission
            </Link>
            <Link
              className="outline-btn"
              to={"https://thedhanacademy.wordpress.com/updates/"}
            >
              <i class="fas fa-newspaper"></i> Updates
            </Link>
            <Link
              className="outline-btn"
              to={"/donate"}
            >
              <i class="fas fa-donate"></i> Donate
            </Link>
          </div>
          <div className="section-content">
            <div className="row">
              <div className="mb-5">
                <Announcement />
              </div>
              <div className="col-md-12 network mb-3">
                <h2 className="title-text">
                  Welcome to <span>The DHAN Academy</span>
                </h2>
                <p className="text-justify">
                  The development sector, particularly grassroots NGOs, requires
                  development professionals to facilitate the development
                  process. The unorganized poor communities need to be provided
                  with an enabling environment to get them organized for
                  accessing and managing resources for their livelihoods
                  development. Enabling the poor would lead to their entitlement
                  and empowerment. There is a need for long, continuous, and
                  rigorous development processes from committed development
                  professionals to bring the desired changes in the lives of the
                  poor.
                </p>
                <p className="text-justify">
                  With this understanding the Academy has set its vision as
                  “becoming a Centre of Excellence” in professionalizing the
                  development process through its quality education, training,
                  research and consultancy programmes. It aims at grooming
                  development professionals into development missionaries by
                  inculcating development values and ethics in them. The Academy
                  believes that these missionaries can facilitate the flow of
                  young, socially concerned, and dynamic professionals into the
                  sector to promote a value based society among the poor.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container pt-3 pb-md-5 pb-3">
          <div className="row text-center">
            <div className="col-sm-4 col-md-4 mb-4 mb-md-0">
              <div className="about-card">
                <div className="about-thumb">
                  <img src={VisionImg} alt="" />
                </div>
                <div className="p-4">
                  <h4 className="font-raleway font-weight-700 mt-15">
                    <a href="#">Vision &amp; Mission</a>
                  </h4>
                  <p>
                    To become “Centre of Excellence in Development Management
                    Education and Research for facilitating large-scale
                    development action at grassroot level
                  </p>
                  <Link
                    className="read-more-btn"
                    to={"/about-us?cat=vision"}
                  >
                    Read more
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 mb-4 mb-md-0">
              <div className="about-card">
                <div className="about-thumb">
                  <img src={GenesisImg} alt="" />
                </div>
                <div className="p-4">
                  <h4 className="font-raleway font-weight-700 mt-15">
                    <a href="#">
                      Genesis<span className="text-theme-colored3"> </span>
                    </a>
                  </h4>
                  <p>
                    The DHAN Academy is a pioneer in the field of development
                    management education with a unique value framework and aims
                    at grooming and mentoring
                  </p>
                  <Link
                    className="read-more-btn"
                    to={"/about-us?cat=genesis"}
                  >
                    Read more
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 mb-4 mb-md-0">
              <div className="about-card">
                <div className="about-thumb">
                  <img src={BoardImg} alt="" />
                </div>
                <div className="p-4">
                  <h4 className="font-raleway font-weight-700 mt-15">
                    <a href="#">Governance</a>
                  </h4>
                  <p>
                    The DHAN Academy is incorporated as a trust under Indian
                    Trust Act, 1882. TDA is governed by the Board of Trustees
                    given above. Decisions regarding
                  </p>
                  <Link
                    className="read-more-btn"
                    to={"/about-us?cat=governance"}
                  >
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section>
        <div className="container mb-md-5 mb-4">
          <h2 className="title-text mb-5">Glimpses</h2>
          <HomeEvents />
        </div>
      </section> */}
      <section className="mb-5">
        <div className="container">
          <h2 className="title-text mb-4">
            Happenings at TDA!
          </h2>
          <div className="row">
            <div className="col-md-6">
              <div>
                {wayOfLife && wayOfLife.map((d, i) => (
                  <iframe
                    width="100%"
                    height="300"
                    className="mb-3"
                    src={d}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                ))}
                {/* <iframe
                    width="100%"
                    height="300"
                    src="https://www.youtube.com/embed/Vs9gkS1Ozws"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe> */}
              </div>
            </div>

            <div className="col-md-6">
              <iframe
                className="w-100"
                height={620}
                src="https://facebook.com/plugins/likebox.php?href=http%3A%2F%2Fwww.facebook.com%2Fthedhanacademy&width=700&colorscheme=light&show_faces=true&border_color&stream=true&header=true&height=620"
                frameBorder={0}
                scrolling="yes"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
