import { useState, useRef } from "react";

export default function MorningRefletion() {
    const headRef = useRef();

    return (
        <div>
            <div ref={headRef}></div>

            <div className="prayer">
                <div className="container-fluid my-5 px-md-5">
                    <div className="row justify-content-center">
                        <div className="col-md-7 align-self-center">
                            <h2>Morning Reflection (Prayer)</h2>
                            <p>
                                The academy prides itself that it not only teaches the subjects but also focusses on improving the attitude and morals of each student. Every Working day morning, students assemble in the prayer hall and share valuable thoughts, reflections, saying by great people and Thirukural Reading. Each student is asked to reflect on any of the 18 core values provided in their student handbook, how they are adopting those values after coming to academy, its challenges and how they are going to improve it as a self-regulatory process.</p>
                        </div>
                        <div className="col-md-5">
                            <iframe
                                width="100%"
                                height="300"
                                className="mb-3"
                                src="https://www.youtube.com/embed/jxYZGFjJ5fg"
                                title="Building Values"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
