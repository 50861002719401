import axios from "axios";
// export const baseurl = `${process.env.REACT_APP_API_URL}`;
// export const apiUrl = baseurl;

let customHeaders = {
  Accept: "application/json",
};

export default axios.create({
  headers: customHeaders,
});
