import { useState, useRef } from "react";

import PageHeader from "../../components/PageHeader";
import Brochure1 from "../../assets/pdf/ACE.pdf";

export default function CCG() {
    const headRef = useRef();

    return (
        <div>
            <PageHeader
                currentPage={"Advance Centre for Enabling Governance"}
                travelPages={[
                    "Centres",
                    " Advance Centre for Enabling Governance",
                ]}
            />
            <div ref={headRef}></div>

            <div className="container-fluid my-5 px-md-5">
                <div className="row justify-content-center">
                    <div className="col-md-8 network">
                        {/* <h2>Advance Centre for Enabling Governance</h2> */}
                        <h6 className="mt-0">Overview</h6>
                        <p>Based on the two-decade field experience with the Panchayat sector,
                            DHAN felt that no institution practises direct experimentation with strong
                            knowledge background – vice versa. And there is a greater space to cater for
                            the needs of field practitioners, officials and academic scholars from an integrative
                            perspective. To fulfill those gaps and trigger the development process in PRIs, the
                            Panchayat development theme of DHAN Foundation is facilitating the promotion of an
                            exclusive Advanced Centre for Enabling Governance (ACE Governance) as part of The DHAN Academy. </p>
                        <ul>
                            <li>
                                <p> Facilitate building, sharing and disseminating of knowledge on field practices on panchayat governance in particular across the field practitioners and other stakeholders and supporting the village panchayat for self-sufficiency measures</p>
                            </li>
                            <li><p>Shape and guide the panchayat representatives in India through action research, reflective practices, policy advocacy and building the capacity of practitioners.</p></li>
                            <li>  <p> Shape and guide the panchayat representatives in India through action research, reflective practices, policy advocacy and building the capacity of practitioners.  </p></li>
                            <li>  <p>Establishing networks for Panchayat representatives to enhance their ASK related to PRI.s  </p></li>
                            <li>  <p>Catering for the needs of practitioners in PRI – techno legal services  </p></li>
                            <li><p>Experimenting & disseminating the best practices for Sectoral contribution</p></li>
                            <li><p>Shape and guide the panchayat representatives and nurture leadership among Panchayats representatives</p></li>
                        </ul>
                    </div>
                    <div className="col-md-3 ">
                        <div className=" centre-broucher">
                            <h6 className="mb-4">Broucher</h6>
                            <div className="d-inline-block">
                                <a className="outline-btn" href="https://thedhanacademy.wordpress.com/archives/" target="_blank">
                                    Brochure
                                </a>
                                <a className="outline-btn  mt-3" href="https://thedhanacademy.wordpress.com/updates/" target="_blank">
                                    Updates
                                </a>
                                <a className="outline-btn mt-3" href="https://thedhanacademy.wordpress.com/archives/" target="_blank">
                                    Publications
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
