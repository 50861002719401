import { useState, useRef } from "react";

import PageHeader from "../../components/PageHeader";
import Brochure1 from "../../assets/pdf/ASKMI.pdf";

export default function ASKMI() {
    const headRef = useRef();

    return (
        <div>
            <PageHeader
                currentPage={" Advanced Centre for Skill and Knowledge on Mutual Insurance (ASKMI)"}
                travelPages={[
                    "Centres",
                    " Advanced Centre for Skill and Knowledge on Mutual Insurance (ASKMI)",
                ]}
            />
            <div ref={headRef}></div>

            <div className="container-fluid my-5 px-md-5">
                <div className="row justify-content-center">
                    <div className="col-md-8 network">
                        {/* <h2>Advanced Centre for Skill and Knowledge on Mutual Insurance (ASKMI)</h2> */}
                        <h6 className="mt-0">Overview</h6>
                        <p>There is a great need for organized research on mutual and micro insurance. The Asian Knowledge Centre for Mutual Insurance—ASKMI—is a specialized centre at the The DHAN Academy designed to involve experts in mutual and micro insurance, local governments, researchers, academic institutions, donors, and international development and aid organizations in integrating and building knowledge about Asia’s mutual insurance. </p>
                        <h6>The major goals of ASKMI are to:</h6>
                        <ul>
                            <li>
                                Facilitate building, sharing, and disseminating knowledge on good and bad practices on micro insurance and mutual insurance to field practitioners and other stakeholders.
                            </li>
                            <li>Shape and guide the promotion of insurance for the poor in Asia through action research, reflective practices, policy advocacy, and capacity building of practitioners.</li>
                            <li>Collaborate with various stakeholders to advance the knowledge and field practices on insurance for the poor in Asia</li>
                        </ul>
                    </div>
                    <div className="col-md-3 ">
                        <div className=" centre-broucher">
                            <h6 className="mb-4">Broucher</h6>
                            <div className="d-inline-block">
                                <a className="outline-btn" href="https://thedhanacademy.wordpress.com/archives/" target="_blank">
                                    Brochure
                                </a>
                                <a className="outline-btn  mt-3" href="https://thedhanacademy.wordpress.com/updates/" target="_blank">
                                    Updates
                                </a>
                                <a className="outline-btn mt-3" href="https://thedhanacademy.wordpress.com/archives/" target="_blank">
                                    Publications
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
