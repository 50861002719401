import { useEffect, useState } from "react";
import { Collapse } from "antd";

import HomeRepository from "../repositories/HomeRepository";

export default function Announcement() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let res = await HomeRepository.getNews();
    let data = [];
    res?.data?.length > 0 &&
      res?.data.map((d, i) => {
        data.push({
          key: i,
          label: <p className="mb-0">{d?.title}</p>,
          children: <p>{d?.description}</p>,
        });
      });
    setNews(data);
  };

  return <Collapse accordion defaultActiveKey={["0"]} items={news} />;
}
