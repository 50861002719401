import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import PageHeader from "../components/PageHeader";

import Vasimalai from "../assets/img/board/vasimalai.jpg";
import Seshadri from "../assets/img/board/seshadri.jpg";
import Umarani from "../assets/img/board/umarani.jpg";
import Trilochan from "../assets/img/board/trilochan-sastry.jpg";

import NoProfile1 from "../assets/img/board/image-3.jpeg";
import NoProfile2 from "../assets/img/board/N-Venkatesan.png";
import NoProfile3 from "../assets/img/board/Guru.png";

import StructureImg from "../assets/img/board/tda-structure.jpeg";
import GovernanceImg from "../assets/img/board/tda-governance.jpg";

export default function AboutUs() {
  const [activeTab, setActiveTab] = useState("vision");

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let cat = searchParams.get("cat");
    if (cat) {
      setActiveTab(cat);
    }
  }, []);


  return (
    <div>
      <PageHeader currentPage={"ABOUT US"} travelPages={["About Us"]} />

      <div className="tab-wrapper mt-4 mb-3">
        <button
          onClick={() => setActiveTab("vision")}
          className={`tab ${activeTab == "vision" ? "active" : ""}`}
        >
          Vision & Mission
        </button>
        <button
          onClick={() => setActiveTab("genesis")}
          className={`tab ${activeTab == "genesis" ? "active" : ""}`}
        >
          Genesis
        </button>
        <button
          onClick={() => setActiveTab("governance")}
          className={`tab ${activeTab == "governance" ? "active" : ""}`}
        >
          Governance
        </button>
      </div>

      {activeTab == "vision" && (
        <div className="container pt-4 pb-4 network">
          <h1 className="title-text mb-md-5 mb-4">Vision & Mission</h1>
          <h2 className="subtitle-text">Vision</h2>
          <p>
            To become “Centre of Excellence in Development Management Education
            and Research for facilitating large-scale development action at
            grassroot level
          </p>
          <h2 className="subtitle-text">Mission</h2>
          <p>
            The mission of the The DHAN Academy is to induct and groom young
            graduates as development missionaries, to build the capacity of
            practitioners in the development sector, and to build and enable a
            world of knowledge and practice through action - refletion - action
            and adapting global knowledge. Excellence, Enabling, Equity,
            Creativity and Discipline in Development Education..
          </p>
        </div>
      )}

      {activeTab == "genesis" && (
        <div className="container pt-4 pb-4 network">
          <h1 className="title-text mb-4">Genesis</h1>
          <p>
            The DHAN Academy is a pioneer in the field of development management
            education with a unique value framework and aims at grooming and
            mentoring young graduates as Development professionals with relevant
            Attitude, Skill and Knowledge. The need for professionals with right
            kind of attitude, high social concern, conviction and commitment to
            work for the cause of uplifting poor and disadvantaged community,
            with adequate multidisciplinary knowledge and with high quality
            techno-managerial competencies has always been felt indispensable by
            the Development sector.
            <br />
            <br />
            To fill this void, DHAN Foundation, a prominent non-government
            organization of Southern India headquartered at Madurai, Tamil Nadu,
            ventured into this education initiative of establishing The DHAN
            Academy at Madurai in January 18, 2001. Inspired by the mission and
            vision of the Academy, Sir Ratan Tata Trust (SRTT) has extended its
            strategic seed support and joined its hand in this noble venture of
            positioning this institute as Centre of Excellence in Development
            management. The academy was initially established at Pulluthu
            location, where the academy ran for 10 years. By August 2010, the
            academy was shifted to new campus at the foot of the Nagamalai
            hills, Malaipatti, Vadipatti Taluk, Madurai District.
          </p>
        </div>
      )}

      {activeTab == "governance" && (
        <div className="container pt-4 pb-4 network">
          <h1 className="title-text mb-md-5 mb-4">
            Board of <span>Trustees</span>
          </h1>
          <div className="row">
            <div className="col-md-3 mb-4">
              <div className="teacher-card">
                <img src={Vasimalai} alt="Vasimalai" />
                <div className="card-text">
                  <h6>Mr. M.P. Vasimalai, Chairperson</h6>
                  <p>Executive Director, DHAN Foundation, Madurai</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="teacher-card">
                <img src={Seshadri} alt="Seshadri" />
                <div className="card-text">
                  <h6>Dr. D.V.R. Seshadri, Vice-chairperson</h6>
                  <p>Professor, Indian School of Business, Hyderabad</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="teacher-card">
                <img src={Umarani} alt="Umarani" />
                <div className="card-text">
                  <h6>Ms. A. Umarani, Treasurer</h6>
                  <p>CEO, DHAN Kalajiam Foundation, Madurai</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="teacher-card">
                <img src={Trilochan} alt="Trilochan Sastry" />
                <div className="card-text">
                  <h6>Dr. Trilochan Sastry, Member</h6>
                  <p>Professor, IIM Bangalore</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="teacher-card">
                <img src={NoProfile1} alt="Karthikeyan" />
                <div className="card-text">
                  <h6>Mr. M. Karthikeyan, Member</h6>
                  <p>CEO, Small Millet Foundation, Madurai</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="teacher-card">
                <img src={NoProfile2} alt="Venkatesan" />
                <div className="card-text">
                  <h6>Mr. N. Venkatesan, Member</h6>
                  <p>CEO, DHAN Vayalagam (Tank) Foundation, Madurai</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="teacher-card">
                <img src={NoProfile3} alt="Gurunathan" />
                <div className="card-text">
                  <h6>Mr. A. Gurunathan, Ex-officio Member Secretary</h6>
                  <p>Director, The DHAN Academy, Madurai</p>
                </div>
              </div>
            </div>
          </div>
          <p className="mt-md-4 mt-3">
            The DHAN Academy is incorporated as a trust under Indian Trust Act,
            1882. TDA is governed by the Board of Trustees given above.
            Decisions regarding the Academy’s curriculum, projects, and other
            activities are managed by its Faculty Council. Committees are
            established within the Academy, to ensure the smooth functioning of
            all activities. And the complete governance structure is given below
          </p>
          <div className="w-md-50 mx-auto my-4 my-md-5">
            <img src={StructureImg} className="w-100" alt="tda-structure" />
          </div>
          <div className="w-md-75 mx-auto my-4 my-md-5">
            <img src={GovernanceImg} className="w-100" alt="tda-governance" />
          </div>
        </div>
      )}
    </div>
  );
}
