import { useState, useRef } from "react";
import { Link } from 'react-router-dom';


import PageHeader from "../../components/PageHeader";
import Uniqueness from "../../components/Uniqueness";
import Structure from "../../components/Structure";
import PIMCourseCredits from "../../components/PIMCourseCredits";

export default function InsuranceManagement() {
    const headRef = useRef();
    const [activeTab, setActiveTab] = useState(1);

    const changeTab = (index) => {
        setActiveTab(index);
        headRef.current.scrollIntoView();
    };

    return (
        <div>
            <PageHeader
                currentPage={"Programme in Insurance Management"}
                travelPages={[
                    "PG Diploma Courses",
                    "Programme in Insurance Management",
                ]}
            />
            <div ref={headRef}></div>

            <div className="container-fluid my-5 px-md-5">
                <div className="row">
                    <div className="col-md-3">
                        <div className="sticky-left">
                            <ul className="list-select">
                                <li
                                    className={activeTab == 1 ? "active" : ""}
                                    onClick={() => changeTab(1)}
                                >
                                    Overview <i className="fad fa-chevron-double-right"></i>
                                </li>
                                <li
                                    className={activeTab == 2 ? "active" : ""}
                                    onClick={() => changeTab(2)}
                                >
                                    Uniqueness <i className="fad fa-chevron-double-right"></i>
                                </li>
                                <Link style={{ color: "#000" }} to="/donate"> <li
                                    className={activeTab == 3 ? "active" : ""}
                                    onClick={() => changeTab(3)}
                                >
                                    Fellowship<i className="fad fa-chevron-double-right"></i>
                                </li></Link>
                                <li
                                    className={activeTab == 4 ? "active" : ""}
                                    onClick={() => changeTab(4)}
                                >
                                    Structure <i className="fad fa-chevron-double-right"></i>
                                </li>
                                <li
                                    className={activeTab == 7 ? "active" : ""}
                                    onClick={() => changeTab(7)}
                                >
                                    Eligibility <i className="fad fa-chevron-double-right"></i>
                                </li>
                                <li
                                    className={activeTab == 5 ? "active" : ""}
                                    onClick={() => changeTab(5)}
                                >
                                    Course Design <i className="fad fa-chevron-double-right"></i>
                                </li>
                                <Link style={{ color: "#000" }} to="/admission"> <li
                                    className={activeTab == 6 ? "active" : ""}
                                    onClick={() => changeTab(6)}
                                >
                                    Admission<i className="fad fa-chevron-double-right"></i>
                                </li></Link>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-9">
                        {activeTab == 1 && (
                            <div className="course-content">
                                <h2>Programme in Insurance Management (PIM)</h2>
                                <p>The Post graduate Diploma Courses objectives are :</p>
                                <ul>
                                    <li>
                                        To inculcate values and attitudes besides required knowledge and skills  to gain managerial excellence  to govern and manage development organizations working in Social secuiry contexts;
                                    </li>
                                    <li>
                                        To contribute nation in  the growth of Third Sector and serve the unserved with passion and dignity
                                    </li>
                                    <li>
                                        To become a highly focused and committed Social entrepreneurs to cater the Social security needs of the community.
                                    </li>
                                </ul>
                                <p>The PIM students are exposed to the basic theories and concepts involved in insurance sector with special focus on micro and mutual insurance. The students will gain practical knowledge in community perspective behaviour and practices towards social security for vulnerable poor. The students will understand the different insurances namely Life insurance, Livestock insurance, Crop insurance, Health insurance, etc. for reaching the poor and vulnerable communities.</p>
                                <h6>Students who complete PIM will:</h6>
                                <ol className="upper-roman">
                                    <li>
                                        Be able to understand social, legal and institutional environment of insurance sector,
                                    </li>
                                    <li>
                                        Understand the social security schemes and products for safeguarding the vulnerable community against the risks and challenges,
                                    </li>
                                    <li>
                                        Develop research, analytical and practical skills to work in micro and mutual insurance sector,

                                    </li>
                                    <li>
                                        Be equipped with strong community focussed skills and techniques to promote people organisations and ensure social security coverage for poor and

                                    </li>
                                    <li>
                                        Be creative, conceptual and critical in thoughts, actions and take development of society as mission of their lives.
                                    </li>

                                </ol>
                            </div>
                        )}
                        {activeTab == 2 && <Uniqueness />}
                        {activeTab == 4 && <Structure />}
                        {activeTab == 5 && <PIMCourseCredits />}
                        {activeTab == 7 && (
                            <div className="course-content">
                                <h2>Eligibility</h2>
                                <h6>General</h6>
                                <ul>
                                    <li>
                                        Graduates Age of the applicant should not be more than 26 years as on 30th June (current year)

                                    </li>
                                    <li>
                                        Graduates waiting for the final semester results by the end of June month (current year) could also apply.

                                    </li>
                                    <li>
                                        Graduates/Post Graduates/Professional graduates working in Indian Non-government organizations/Civil society organizations who fulfill all the above criteria are eligible to apply.

                                    </li>
                                    <li>
                                        Willing to work in designated Non-Government Organization for minimum three years to serve the poor people with a fairly decent monthly remuneration as per the norms of each NGO.

                                    </li>
                                    <li>
                                        Graduates who complete Successfully two years of study and three years of services in placed NGOs without break will be awarded PG Diploma in Micro Insurance Management along with the grade sheet during convocation.

                                    </li>
                                    <li>
                                        English is the medium of teach. So English proficiency is important.
                                    </li>
                                </ul>
                                <h6>PIM</h6>
                                <p style={{ fontWeight: 500 }}>Passed with a minimum 60% of marks (or equivalent) in any Science or Arts Degree, like B.Sc.,/ M.Sc (Maths), Physics, Statistics, B.Sc (Agri), B.Sc. (Horti)
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
