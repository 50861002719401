import { useState, useRef } from "react";

import PageHeader from "../../components/PageHeader";
import Uniqueness from "../../components/Uniqueness";
import Structure from "../../components/Structure";
import PCMCourseCredits from "../../components/PCMCourseCredits";
import { Link } from 'react-router-dom';

export default function CommunityHealthManagement() {
    const headRef = useRef();
    const [activeTab, setActiveTab] = useState(1);

    const changeTab = (index) => {
        setActiveTab(index);
        headRef.current.scrollIntoView();
    };

    return (
        <div>
            <PageHeader
                currentPage={"Programme in Community Health management"}
                travelPages={[
                    "PG Diploma Courses",
                    "Programme in Community Health management",
                ]}
            />
            <div ref={headRef}></div>

            <div className="container-fluid my-5 px-md-5">
                <div className="row">
                    <div className="col-md-3">
                        <div className="sticky-left">
                            <ul className="list-select">
                                <li
                                    className={activeTab == 1 ? "active" : ""}
                                    onClick={() => changeTab(1)}
                                >
                                    Overview <i className="fad fa-chevron-double-right"></i>
                                </li>
                                <li
                                    className={activeTab == 2 ? "active" : ""}
                                    onClick={() => changeTab(2)}
                                >
                                    Uniqueness <i className="fad fa-chevron-double-right"></i>
                                </li>
                                <Link style={{ color: "#000" }} to="/donate"> <li
                                    className={activeTab == 3 ? "active" : ""}
                                    onClick={() => changeTab(3)}
                                >
                                    Fellowship <i className="fad fa-chevron-double-right"></i>
                                </li></Link>
                                <li
                                    className={activeTab == 4 ? "active" : ""}
                                    onClick={() => changeTab(4)}
                                >
                                    Structure <i className="fad fa-chevron-double-right"></i>
                                </li>
                                <li
                                    className={activeTab == 7 ? "active" : ""}
                                    onClick={() => changeTab(7)}
                                >
                                    Eligibility <i className="fad fa-chevron-double-right"></i>
                                </li>
                                <li
                                    className={activeTab == 5 ? "active" : ""}
                                    onClick={() => changeTab(5)}
                                >
                                    Course Design <i className="fad fa-chevron-double-right"></i>
                                </li>
                                <Link style={{ color: "#000" }} to="/admission"> <li
                                    className={activeTab == 6 ? "active" : ""}
                                    onClick={() => changeTab(6)}
                                >
                                    Admission <i className="fad fa-chevron-double-right"></i>
                                </li></Link>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-9">
                        {activeTab == 1 && (
                            <div className="course-content">
                                <h2>Programme in Community Health management (PDM)</h2>
                                <p>The Post graduate Diploma Courses objectives are :</p>
                                <ul>
                                    <li>
                                        To inculcate values and attitudes besides required knowledge and skills  to gain managerial excellence  to govern and manage development organizations working in Health and its relavant contexts;
                                    </li>
                                    <li>
                                        To contribute nation in  the growth of Third Sector and serve the unserved with passion and dignity
                                    </li>
                                    <li>
                                        To become a highly focused and committed Social entrepreneurs to cater the health needs of the community.
                                    </li>
                                </ul>
                                <h6>Students who complete the PCM will:</h6>
                                <ol className="upper-roman">
                                    <li>
                                        Understand the grassroot realities of health conditions and basic concepts relevant to community health
                                    </li>
                                    <li>
                                        Able to relate and analysis the micro and macro level policy regulations and its impact
                                    </li>
                                    <li>
                                        Develop the research skills to health-related issues and challenges of community and develop intervention models
                                    </li>
                                    <li>
                                        Be equipped with strong community focused skills and techniques to promote people organizations and health related livelihood activities
                                    </li>
                                    <li>
                                        Be creative, conceptual and critical in thoughts and actions and take development of the society as mission of their lives
                                    </li>
                                </ol>
                                <p>The PCM students are exposed to the basic theories and concepts involved in community health management to understand the people’s perceptions behavior and practices towards the health. They will understand the health policies, how to study the demography, evolve and manage health projects for higher impact among the community.
                                </p>
                            </div>
                        )}
                        {activeTab == 2 && <Uniqueness />}
                        {activeTab == 4 && <Structure />}
                        {activeTab == 5 && <PCMCourseCredits />}
                        {activeTab == 7 && (
                            <div className="course-content">
                                <h2>Eligibility</h2>
                                <h6>General</h6>
                                <ul>
                                    <li>
                                        Graduates Age of the applicant should not be more than 26 years as on 30th June (current year)

                                    </li>
                                    <li>
                                        Graduates waiting for the final semester results by the end of June month (current year) could also apply.

                                    </li>
                                    <li>
                                        Graduates/Post Graduates/Professional graduates working in Indian Non-government organizations/Civil society organizations who fulfill all the above criteria are eligible to apply.

                                    </li>
                                    <li>
                                        Willing to work in designated Non-Government Organization for minimum three years to serve the poor people with a fairly decent monthly remuneration as per the norms of each NGO.

                                    </li>
                                    <li>
                                        Graduates who complete Successfully two years of study and three years of services in placed NGOs without break will be awarded PG Diploma in Micro Insurance Management along with the grade sheet during convocation.

                                    </li>
                                    <li>
                                        English is the medium of teach. So English proficiency is important.
                                    </li>
                                </ul>
                                <h6>PCM</h6>
                                <p style={{ fontWeight: 500 }}>Applicant must be in any Science or Arts Degree, Medical and Paramedical courses like B. Pharm, BPT (Physiotherapy), B.Sc. (Nursing), B.E. (Bio-Medical Engineering) and 3 years diploma in Nursing 60% with a minimum of marks (or equivalent) awarded by recognized Indian universities and / or colleges.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
