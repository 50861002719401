import { useState } from "react";
import Slider from "react-slick";

import PageHeader from "../components/PageHeader";

import PolicyBrief from "../assets/img/network/PolicyBrief.png";
import Compendium from "../assets/img/network/Compendium.jpg";
import Anex1Image from "../assets/img/network/Anex1Image.png";
import Annex2Image from "../assets/img/network/Annex2Image.png";
import ProjectIntro from "../assets/img/network/ProjectIntro.png";

export default function Network() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const [activeTab, setActiveTab] = useState("overview");

  return (
    <div>
      <PageHeader
        currentPage={"Network"}
        travelPages={[
          "External Relations",
          "Network",
          "Agriculture and Water Pollution South Asia Network",
        ]}
      />
      <div className="container py-md-5 py-4 network">
        <h1 className="title-text mb-md-5 mb-4">
          Agriculture and Water Pollution South Asia Network (AGRIWAPSAN)
        </h1>
        <div className="tab-wrapper mt-4 mb-3">
          <button
            onClick={() => setActiveTab("overview")}
            className={`tab ${activeTab == "overview" ? "active" : ""}`}
          >
            Overview
          </button>
          <button
            onClick={() => setActiveTab("online-courses")}
            className={`tab ${activeTab == "online-courses" ? "active" : ""}`}
          >
            Online Courses
          </button>
        </div>
        {activeTab == "overview" && (
          <div className="row">
            <div className="col-md-8">
              <h2 className="subtitle-text">Overview</h2>
              <p>
                ‘South Asia Environmental Capacity Building – Agriculture and
                Water Pollution Project’ is the project being implemented by
                DHAN Foundation in India, Arthcharya Foundation in Sri Lanka,
                LiBird in Nepal and CARITAS Bangladesh in Bangladesh, with the
                support of US Department of state and CACH. The project started
                from October 2017 to at present focusing on four key areas such
                as Policy gap analysis, Capacity Building, Action research and
                Regional Network. Under this project, it is proposed to have a
                knowledge sharing platform to disseminate the knowledge to a
                wider audience. This website will act as a platform for
                disseminating information on ‘Agriculture and Water Pollution &
                Land Based Water Pollution’ at South Asia level.
                <br /> <br />
                DHAN Foundation is taking lead and created this online platform
                in continuation of the project. This will also complement the
                online course run by DHAN Foundation on ‘Land based water
                Pollution’.
                <br /> <br />
                Key purposes of this platform are
              </p>
              <ul>
                <li>
                  To create an online knowledge base on ‘Agriculture and Water
                  pollution’ and disseminate the case studies, policy briefs and
                  other key outcomes of the project implemented
                </li>
                <li>
                  To serve as the cross and co learning platform for various
                  stakeholders across the South Asian level
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <h2 className="subtitle-text">References</h2>
              <Slider {...settings} className="primary-slider">
                <a
                  href="https://dhan.org/tda/network/docs/Annexure%201%20Policy%20Brief.pdf"
                  target="_blank"
                >
                  <div className="network-card">
                    <img src={PolicyBrief} alt="" />
                    <h6>South Asia Regional Policy Brief</h6>
                  </div>
                </a>
                <a
                  href="https://dhan.org/tda/network/docs/Good%20Practices%20Compendium-Final-1%20Feb%202021.pdf"
                  target="_blank"
                >
                  <div className="network-card">
                    <img src={Compendium} alt="" />
                    <h6>South Asia regional Compendium of Good Practises</h6>
                  </div>
                </a>
                <a
                  href="https://dhan.org/tda/network/docs/Annexure%201%20Policy%20Brief.pdf"
                  target="_blank"
                >
                  <div className="network-card">
                    <img src={Anex1Image} alt="" />
                    <h6>Policy Brief</h6>
                  </div>
                </a>
                <a
                  href="https://dhan.org/tda/network/docs/Annexure%202%20Moving%20to%20Responsible%20Agriculture%20(crown%20size)%20-%2028.12.2020.pdf"
                  target="_blank"
                >
                  <div className="network-card">
                    <img src={Annex2Image} alt="" />
                    <h6>Moving to Responsible Agriculture</h6>
                  </div>
                </a>
                <a
                  href="https://dhan.org/tda/network/docs/Project%20Intro%20Sheet-CARITAS-As%20of%208%20June%202018.pdf"
                  target="_blank"
                >
                  <div className="network-card">
                    <img src={ProjectIntro} alt="" />
                    <h6>Project Brochure</h6>
                  </div>
                </a>
              </Slider>
            </div>
          </div>
        )}
        {activeTab == "online-courses" && (
          <div className="online-courses">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h2 className="subtitle-text mb-0">
                Online course on Land based water pollution
              </h2>
              <button className="outline-btn">Apply Now</button>
            </div>
            <h6>Credit Course on Land Based Water Pollution</h6>
            <p>
              This course addresses the problem of land-based water pollution
              mainly arising from non-point source pollution from agriculture
              and poor sanitation practices. It will highlight the sources and
              impacts linked to land-based water pollution and identify ways in
              which pollution control maybe implemented for sustainable results.
              Gaps in regulatory mechanisms and management practices will be
              highlighted to build the case for an integrated approach to
              planning and implementing solutions. Examples of mitigation
              measures will also be provided to help develop an understanding of
              approaches/concepts that maybe successfully implemented.
            </p>
            <h6>Target Group</h6>
            <p>
              Students or Professionals from NGOs, Government working on
              Agricultural / Environmental / Water Resource Management
            </p>
            <h6>Level</h6>
            <p>Beginner/Basic</p>
            <h6>Module and Timeline</h6>
            <p>4 modules and 4 months</p>
            <h6>Course Fee Structure</h6>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>For Indian participants:</th>
                  <th>Account Details:</th>
                  <th>For International participants:</th>
                  <th>Account Details :</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Rs. 1000 + 18% GST = Rs. 1180</td>
                  <td>
                    Account Name: The DHAN Academy Account No.: 1007101045516
                    Bank and Branch: Canara Bank, Ramanad Road Branch, Madurai
                    IFS Code: CNRB0001007
                  </td>
                  <td>USD 20 Dollars + 18% GST</td>
                  <td>
                    International bank transfer to Account Name: DHAN Foundation
                    SB Account No.: 1013101028758 Swift Code: CNR BIN BB UFM.
                    IFSC Code: CNRB0001013
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
