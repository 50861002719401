import React from 'react'
import { Outlet } from 'react-router-dom'

import Navbar from './Navbar'
import Footer from './Footer'
import ScrollToTop from "../../helper/ScrollToTop";

export default function Index() {

  return (
    <div className='base-layout'>
      <div className='main'>
        <ScrollToTop />
        <Navbar />
        <div className='page-content'>
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  )
}
