import { useState, useRef } from "react";

import PageHeader from "../../components/PageHeader";
import Brochure1 from "../../assets/pdf/ACESUHAM.pdf";

export default function ACESE() {
    const headRef = useRef();

    return (
        <div>
            <PageHeader
                currentPage={" Advanced Centre for Enabling Social entrepreneurs (ACESE)"}
                travelPages={[
                    "Centres",
                    " Advanced Centre for Enabling Social entrepreneurs (ACESE)",
                ]}
            />
            <div ref={headRef}></div>

            <div className="container-fluid my-5 px-md-5">
                <div className="row justify-content-center">
                    <div className="col-md-8 network">
                        {/* <h2>Advanced Centre for Enabling Social entrepreneurs (ACESE)</h2> */}
                        <h6 className="mt-0">Overview</h6>
                        <p>The centre is the livelihood advancement initiative of DHAN Foundation. The centre has been initiated for evolving the
                            model for livelihood graduation of small and marginal producers involved in both farm and nonfarm activities
                            for increasing their income. The ACESE has promoted incubation centre and school for sustainable production.
                            The incubation centre focuses on promoting and sustaining the farmer producer Institutions. The centre will offer
                            capacity building for the producers and the producer Institutions. At present the ACESE, handholds farmer producer
                            Institutions which are promoted by Jeevidam programme of DHAN Foundation across country. To have a common
                            identity the FPOs and their products are branded as Jeevidam.  </p>
                        <p>The purpose of Advanced Center for Enabling Social Enterprise</p>
                        <ul>
                            <li>
                                Incubating the theme with care and evolving the right kind of model-
                                innovation management
                            </li>
                            <li>Knowledge management through documentation and research</li>
                            <li>Knowledge dissemination through capacity building of all stakeholders.</li>
                        </ul>
                    </div>
                    <div className="col-md-3 ">
                        <div className=" centre-broucher">
                            <h6 className="mb-4">Broucher</h6>
                            <div className="d-inline-block">
                                <a className="outline-btn" href="https://thedhanacademy.wordpress.com/archives/" target="_blank">
                                    Brochure
                                </a>
                                <a className="outline-btn  mt-3" href="https://thedhanacademy.wordpress.com/updates/" target="_blank">
                                    Updates
                                </a>
                                <a className="outline-btn mt-3" href="https://thedhanacademy.wordpress.com/archives/" target="_blank">
                                    Publications
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
