import React from "react";

import Picture1 from "../assets/img/uniqueness/Picture1.jpg";
import Picture2 from "../assets/img/uniqueness/Picture2.jpg";
import Picture3 from "../assets/img/uniqueness/Picture3.png";
import Picture4 from "../assets/img/uniqueness/Picture4.jpg";

export default function Uniqueness() {
  return (
    <div className="course-content">
      <h2>Uniqueness of TDA</h2>
      <div className="row align-items-center mb-4">
        <div className="col-md-5 h-100">
          <div className="w-75 mb-4">
            <img src={Picture1} className="img-fluid" alt="" />
          </div>
          <div className="w-75 ms-auto">
            <img src={Picture2} className="img-fluid" alt="" />
          </div>
        </div>
        <div className="col-md-7 h-100">
          <img src={Picture3} className="img-fluid" alt="" />
        </div>
      </div>
      <h6>“Young Graduates” to “Development Professionals”</h6>
      <div className="row align-items-center">
        <div className="col-md-7">
          <p>The DHAN Academy’s unique approach encourages students to:</p>
          <ol>
            <li>
              Broaden their development perspectives by&nbsp;
              <b>exposing students to field realities.</b>
            </li>
            <li>
              Improve their <b>abilities in research</b> to identify development
              issues and design and implement interventions.
            </li>
            <li>
              Be equipped with the appropriate managerial techniques and tools
              to <b>build people’s organizations</b> and promote livelihood
              development activities.
            </li>
            <li>
              Build <b>“people-focused skills”</b> for motivating people and
              building and leading strong and effective teams and organizations.
            </li>
            <li>
              Be <b>creative, conceptual, and critical</b> in all efforts.
            </li>
          </ol>
        </div>
        <div className="col-md-5">
          <img src={Picture4} className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  );
}
