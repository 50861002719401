import React from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";

import "antd/dist/reset.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/gobal.css";
import "./assets/css/antd.customize.scss";
import "./assets/css/style.scss";

import App from "./App";

// axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["Accept"] = "application/json";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
