import { useState, useRef } from "react";

import PageHeader from "../../components/PageHeader";
import Brochure1 from "../../assets/pdf/WKC.pdf";

export default function WKC() {
    const headRef = useRef();

    return (
        <div>
            <PageHeader
                currentPage={" Water Knowledge Centre (WKC)"}
                travelPages={[
                    "Centres",
                    " Water Knowledge Centre (WKC)",
                ]}
            />
            <div ref={headRef}></div>

            <div className="container-fluid my-5 px-md-5">
                <div className="row justify-content-center">
                    <div className="col-md-8 network">
                        {/* <h2>Water Knowledge Centre (WKC)</h2> */}
                        <h6 className="mt-0">Overview</h6>
                        <p>Water Knowledge Centre (WKC) aims at enhancing the knowledge and practice on integrated water resource management by working with peoples’ institutions, government and non-government organizations, technical institutes, research and academic institutes and funding agencies. WKC would provide a platform to identify, appreciate and nurture local knowledge and wisdom on community-led Water resources management. It will encourage the practitioners promoting collective action around water resources management and aid influencing state and national policies in tune with community’s needs and aspirations.</p>
                    </div>
                    <div className="col-md-3 ">
                        <div className=" centre-broucher">
                            <h6 className="mb-4">Broucher</h6>
                            <div className="d-inline-block">
                                <a className="outline-btn" href="https://thedhanacademy.wordpress.com/archives/" target="_blank">
                                    Brochure
                                </a>
                                <a className="outline-btn  mt-3" href="https://thedhanacademy.wordpress.com/updates/" target="_blank">
                                    Updates
                                </a>
                                <a className="outline-btn mt-3" href="https://thedhanacademy.wordpress.com/archives/" target="_blank">
                                    Publications
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
