import { useState, useRef } from "react";

import PageHeader from "../components/PageHeader";
import { Link } from 'react-router-dom';


export default function Admission() {
    const headRef = useRef();
    const [activeTab, setActiveTab] = useState(1);

    const changeTab = (index) => {
        setActiveTab(index);
        headRef.current.scrollIntoView();
    };

    return (
        <div>
            <PageHeader
                currentPage={"Admission"}
                travelPages={[
                    "Academics",
                    "Admission",
                ]}
            />
            <div ref={headRef}></div>

            <div className="container-fluid my-5 px-md-5">
                <div className="row">
                    <div className="col-md-3">
                        <div className="sticky-left">
                            <ul className="list-select">
                                <li
                                    className={activeTab == 1 ? "active" : ""}
                                    onClick={() => changeTab(1)}
                                >
                                    Overview <i className="fad fa-chevron-double-right"></i>
                                </li>
                                <li
                                    className={activeTab == 2 ? "active" : ""}
                                    onClick={() => changeTab(2)}
                                >
                                    Eligibility criteria <i className="fad fa-chevron-double-right"></i>
                                </li>
                                <Link style={{ color: "#000" }} to="https://forms.gle/VGW7YnHpF8PcBUpz7"><li
                                    className={activeTab == 3 ? "active" : ""}
                                    onClick={() => changeTab(3)}
                                >
                                    Apply online link  <i className="fad fa-chevron-double-right"></i>
                                </li></Link>
                                <li
                                    className={activeTab == 4 ? "active" : ""}
                                    onClick={() => changeTab(4)}
                                >
                                    About Programmes <i className="fad fa-chevron-double-right"></i>
                                </li>
                                <Link style={{ color: "#000" }} to="https://thedhanacademy.wordpress.com/archives/"><li
                                    className={activeTab == 7 ? "active" : ""}
                                    onClick={() => changeTab(5)}
                                >
                                    Brochure <i className="fad fa-chevron-double-right"></i>
                                </li></Link>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-9">
                        {activeTab == 1 && (
                            <div className="course-content">
                                <h2>Admission Overview</h2>
                                <p>The DHAN Academy’s admission process is a unique one which more focusses on the students attitude to work with the community and at grassroot level. For that, academy has designed the admission process in such a way  two tests:
                                </p>
                                <h6>Process</h6>
                                <p><strong>Stage 1: </strong></p>
                                <p>Application (online Google)</p>
                                <ul>
                                    <li><p>Written test – Assessment of basic English, Mathematics,</p></li>
                                    <li><p>Group Discussion: A short caselet will be provided. Candidates have to read and discuss.</p></li>
                                    <li><p>Personal Interview -  to assess their Motivation and Attitude to work with community
                                        (this may happen either through online / centres chosen by academy)</p></li>
                                </ul>
                                <p><strong>Stage 2: </strong></p>
                                <ul>
                                    <li> <p>Village stay and Presentation: Selected candidates have to travel to academy and stay in villages of Tamil Nadu for 3 to 5 days and make presentation of their insights.
                                        (Note: Travel cost to academy (Sleeper ticket cost), boarding and lodging (for the 5 days at campus and village) will be taken care by academy) Train</p>
                                    </li>
                                    <li><p>Shortlisted Candidates Announced</p></li>
                                    <li><p>Induction into the course</p></li>
                                </ul>
                            </div>
                        )}
                        {activeTab == 2 && (
                            <div className="course-content">
                                <h2>Eligibility</h2>
                                <h6>Attitude and Internal drive to stay , study and work with Community of any context
                                </h6>
                                <ul>
                                    <li>
                                        <p>Age of the applicant should not be more than 26 years as on 30th August (current year)</p>
                                    </li>
                                    <li>
                                        <p>Basic Oral and Written English Knowledge</p>
                                    </li>
                                    <li>
                                        <p>Basic Computer Skills (MS Word, Excel and Presentation)</p>
                                    </li>
                                </ul>
                                <h6>PDM</h6>
                                <p >Applicant must be any graduate of Arts / Science / Social or Bio-sciences / Commerce / Economics passed with a minimum 60% of marks (or equivalent) awarded by recognized Indian Universities / Colleges.
                                </p>
                                <p>Graduates waiting for the final semester results by end June (current) could also apply. (or Applicant must be any Professional graduates in Agriculture and Allied Sciences, Engineering and Technology, passed with a minimum 60% of marks (or equivalent) awarded by recognized Indian universities and/or colleges
                                </p>
                                <h6>PCM</h6>
                                <p>Pass in any Science or Arts Degree related to Medical and Paramedical courses like B. Pharm, BPT (Physiotherapy). B.Sc (Nursing), B.E (Bio Medical Engineering). And Micro biology, Bio Tech courses also
                                </p>
                                <h6>PIM</h6>
                                <p>Passed with a minimum 60% of marks (or equivalent) in any Science or Arts Degree, like B.Sc.,/ M.Sc  (Maths), Physics, Statistics, B.Sc (Agri), B.Sc. (Horti)
                                </p>
                                <ul>
                                    <li><p>Graduates/Post Graduates/Professional graduates working in Indian Non-government organizations/Civil society organizations who fulfill all the above criteria are eligible to apply.
                                    </p></li>
                                    <li><p>Willing to work in designated Non-Government Organization (decided by academy) for minimum three years to serve the poor people with a fairly decent monthly remuneration as per the norms of each NGO.
                                    </p></li>
                                    <li><p>Graduates who complete Successfully two years of study and three years of service in placed NGOs without break will only be awarded PG Diploma along with the grade sheet during convocation.
                                    </p></li>
                                </ul>
                            </div>
                        )}
                        {activeTab == 4 && (
                            <div className="course-content">
                                <h2>About Programmes</h2>
                                <h6>PDM</h6>
                                <p >Applicant must be any graduate of Arts / Science / Social or Bio-sciences / Commerce / Economics passed with a minimum 60% of marks (or equivalent) awarded by recognized Indian Universities / Colleges.
                                </p>
                                <Link className="btn btn-primary mb-4" style={{ backgroundColor: '#23105e', borderColor: '#23105e' }} to="/pg-diploma-courses/development-management">Read More about PDM</Link>
                                <p>Graduates waiting for the final semester results by end June (current) could also apply. (or Applicant must be any Professional graduates in Agriculture and Allied Sciences, Engineering and Technology, passed with a minimum 60% of marks (or equivalent) awarded by recognized Indian universities and/or colleges
                                </p>
                                <h6>PCM</h6>
                                <p>Pass in any Science or Arts Degree related to Medical and Paramedical courses like B. Pharm, BPT (Physiotherapy). B.Sc (Nursing), B.E (Bio Medical Engineering). And Micro biology, Bio Tech courses also
                                </p>
                                <Link className="btn btn-primary mb-4" style={{ backgroundColor: '#23105e', borderColor: '#23105e' }} to="/pg-diploma-courses/community-health-management">Read More about PCM</Link>
                                <h6>PIM</h6>
                                <p>Passed with a minimum 60% of marks (or equivalent) in any Science or Arts Degree, like B.Sc.,/ M.Sc  (Maths), Physics, Statistics, B.Sc (Agri), B.Sc. (Horti)
                                </p>
                                <Link className="btn btn-primary mb-4" style={{ backgroundColor: '#23105e', borderColor: '#23105e' }} to="/pg-diploma-courses/insurance-management">Read More about PIM</Link>

                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div >
    );
}
