import Home from "../pages/home";
import AboutUs from "../pages/about";
import Faculty from "../pages/faculty";
import Students from "../pages/students";
import Contact from "../pages/contact";
import Research from "../pages/research";
import Education from "../pages/executive-education";

import DevelopmentManagement from "../pages/courses/development-management";
import CommunityHealthManagement from "../pages/courses/community-health-management";
import InsuranceManagement from "../pages/courses/insurance-management";
import Network from "../pages/courses/network";
import Admission from "../pages/admission";
import Donate from "../pages/donate";

import ACEWE from "../pages/centers/acewe";
import ACEDRR from "../pages/centers/acedrr";
import ACEFIN from "../pages/centers/acefin";
import ACESE from "../pages/centers/acese";
import ACESUHAM from "../pages/centers/acesuham";
import ASKMI from "../pages/centers/askmi";
import CCG from "../pages/centers/ccg";
import MRC from "../pages/centers/mrc";
import WKC from "../pages/centers/wkc";

import Reflection from "../pages/life-tda/reflection";
import Yoga from "../pages/life-tda/yoga";
import Recreation from "../pages/life-tda/recreation";
import Structure from "../pages/life-tda/structure";

import Placement from "../pages/placement";
import SouthAsiaNetwork from "../pages/south-asia-network";

import Immersion from "../pages/immersion";
import Alumni from "../pages/alumni";

import NotFound from "../pages/not-found";

const routes = [
  {
    name: "Home",
    path: "/",
    component: <Home />,
  },
  {
    name: "About Us",
    path: "/about-us",
    component: <AboutUs />,
  },
  {
    name: "Faculty",
    path: "/faculty",
    component: <Faculty />,
  },
  {
    name: "Students",
    path: "/students",
    component: <Students />,
  },
  {
    name: "Contact",
    path: "/contact",
    component: <Contact />,
  },
  {
    name: "Research",
    path: "/research",
    component: <Research />,
  },
  {
    name: "Alumni",
    path: "/alumni",
    component: <Alumni />,
  },
  {
    name: "Executive Education",
    path: "/executive-education",
    component: <Education />,
  },
  {
    name: "Programme in Development Management",
    path: "/pg-diploma-courses/development-management",
    component: <DevelopmentManagement />,
  },
  {
    name: "Programme in Community Health Management",
    path: "/pg-diploma-courses/community-health-management",
    component: <CommunityHealthManagement />,
  },
  {
    name: "Programme in Insurance Management",
    path: "/pg-diploma-courses/insurance-management",
    component: <InsuranceManagement />,
  },
  {
    name: "Land Based Water Pollution ",
    path: "/online-courses/land-based-waterpollution",
    component: <Network />,
  },
  {
    name: "Advanced Centre for Enabling Women Enterprises (ACEWE)",
    path: "/centers/acewe",
    component: <ACEWE />,
  },
  {
    name: "Advance Centre for Enabling Governance",
    path: "/centers/ccg",
    component: <CCG />,
  },
  {
    name: "Media Resource Centre",
    path: "/centers/mrc",
    component: <MRC />,
  },
  {
    name: "Water Knowledge Centre (WKC)",
    path: "/centers/wkc",
    component: <WKC />,
  },
  {
    name: "Advanced Centre for Skill and Knowledge on Mutual Insurance (ASKMI)",
    path: "/centers/askmi",
    component: <ASKMI />,
  },
  {
    name: "Advanced Centre for Enabling Sustainable Health care advancement (ACESUHAM)",
    path: "/centers/acesuham",
    component: <ACESUHAM />,
  },
  {
    name: "Advanced Centre for Enabling Social entrepreneurs (ACESE)",
    path: "/centers/acese",
    component: <ACESE />,
  },
  {
    name: "Advanced Centre of Excellence in Financial Management for Development (ACEFIN)",
    path: "/centers/acefin",
    component: <ACEFIN />,
  },
  {
    name: "Advanced Centre for Enabling Disaster Risk Reduction (ACEDRR)",
    path: "/centers/acedrr",
    component: <ACEDRR />,
  },
  {
    name: "Morning Reflection ",
    path: "/morning-reflection",
    component: <Reflection />,
  },
  {
    name: "Yoga",
    path: "/yoga",
    component: <Yoga />,
  },
  {
    name: "Recreation & Shramadan",
    path: "/recreation",
    component: <Recreation />,
  },
  {
    name: "Infrastructure",
    path: "/structure",
    component: <Structure />,
  },
  {
    name: "Immersion Programme",
    path: "/immersion",
    component: <Immersion />,
  },
  {
    name: "Placement",
    path: "/placement",
    component: <Placement />,
  },
  {
    name: "Admission",
    path: "/admission",
    component: <Admission />,
  },
  {
    name: "Donate",
    path: "/donate",
    component: <Donate />,
  },
  {
    name: "South Asia Network",
    path: "/south-asia-network",
    component: <SouthAsiaNetwork />,
  },
  {
    name: "Error 404",
    path: "*",
    component: <NotFound />,
  },
];

export default routes;
