import { useState, useRef } from "react";

import PageHeader from "../../components/PageHeader";
import Brochure1 from "../../assets/pdf/ACEDF.pdf";

export default function ACEFIN() {
    const headRef = useRef();

    return (
        <div>
            <PageHeader
                currentPage={" Advanced Centre of Excellence in Financial Management for Development (ACEFIN)"}
                travelPages={[
                    "Centres",
                    " Advanced Centre of Excellence in Financial Management for Development (ACEFIN)",
                ]}
            />
            <div ref={headRef}></div>

            <div className="container-fluid my-5 px-md-5">
                <div className="row justify-content-center">
                    <div className="col-md-8 network">
                        {/* <h2>Advanced Centre of Excellence in Financial Management for Development (ACEFIN)</h2> */}
                        <h6 className="mt-0">Overview</h6>
                        <p>Keeping in view of the above developments, The Dhan Academy, a development educational Institute, promoted by The Dhan Foundation, a leading Nonprofit organization in India with more 25 years of experience in the development sector, has ventured into setting up of an Advanced Centre of Excellence in Development Finance  (ACEFIN) to impart advanced skills to manage the finance more professionally and create the desired results specially for micro level and macro level participating institutions  in development  community organizations.
                        </p>
                        <p>ACEFIN will endeavor to identify the gap areas of skills through research and feedback from the stakeholders and community. Based on the needs, appropriate knowledge management systems will be evolved including knowledge dissemination, research, documentation publication, training, education and public policy. This will enable the stakeholders to face the challenges in economy and bring success to the community.</p>
                        <ul>
                            <li>
                                <p>Facilitate building, sharing and disseminating of knowledge on finance management practices on development finance in general, and improve skills to manage finance efficiently for better results across the field practitioners and other stakeholders.
                                </p>
                            </li>
                            <li>
                                <p>Shape and guide the development finance professionals through action research, reflective practices, policy advocacy and building the capacity of practitioners.
                                </p>
                            </li>
                            <li>
                                <p> Collaborate with various stakeholders to advance the knowledge and impart improved practices in development finance  </p>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <div className=" centre-broucher">
                            <h6 className="mb-4">Broucher</h6>
                            <div className="d-inline-block">
                                <a className="outline-btn" href="https://thedhanacademy.wordpress.com/archives/" target="_blank">
                                    Brochure
                                </a>
                                <a className="outline-btn  mt-3" href="https://thedhanacademy.wordpress.com/updates/" target="_blank">
                                    Updates
                                </a>
                                <a className="outline-btn mt-3" href="https://thedhanacademy.wordpress.com/archives/" target="_blank">
                                    Publications
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
