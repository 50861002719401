import React from "react";

import PageHeader from "../components/PageHeader";

export default function Contact() {
  return (
    <div>
      <PageHeader
        currentPage={"CONTACT"}
        travelPages={["About Us", "Contact"]}
      />
      <div className="container pt-md-5 pt-4 pb-5">
        <h1 className="title-text mb-md-5 mb-4">Contact Us</h1>
        <div className="row mb-4 mb-md-5">
          <div className="col-md-4">
            <div className="contact-card">
              <h6>
                <i className="far fa-phone-alt"></i> Call Us
              </h6>
              <p>
                96775 83405 <br />
                +91 4543 299 989 <br /> contact only during 9 am to 5pm
              </p>

            </div>
          </div>
          <div className="col-md-4">
            <div className="contact-card">
              <h6>
                <i className="far fa-map-marker-alt"></i> Address
              </h6>
              <p>
                The DHAN Academy, T. Malaipatti, <br /> Thenkarai, Mullipallam
                (PO), <br />
                Melakkal (Via), Madurai - 625207.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="contact-card">
              <h6>
                <i className="far fa-envelope"></i> Email
              </h6>
              <p>
                tda@dhan.org <br />
                admissions.tda@dhan.org <br />
                thedhanacademymedia@gmail.com
              </p>
            </div>
          </div>
        </div>
        <div className="student-card">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.2921670945298!2d77.95705561488549!3d9.992707292856153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00c94ca9e926dd%3A0x89038c1edd009176!2sTata-Dhan+Academy!5e0!3m2!1sen!2sin!4v1516362921747"
            width="100%"
            height="450"
            frameborder="0"
            style={{ border: 0 }}
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}
