import { useState, useRef } from "react";
import PageHeader from "../../components/PageHeader";

import SiteMap from "../../assets/img/structure/site.png"
import VaigaiHall from "../../assets/img/structure/tda-vaigai-hall.jpg"
import Library1 from "../../assets/img/structure/library1.jpeg"
import Library2 from "../../assets/img/structure/library2.jpeg"
import Library3 from "../../assets/img/structure/library3.jpeg"
import Library4 from "../../assets/img/structure/library4.jpeg"
import Library5 from "../../assets/img/structure/library5.jpeg"
import Library6 from "../../assets/img/structure/library6.jpeg"
import Library7 from "../../assets/img/structure/library7.jpeg"
import Library8 from "../../assets/img/structure/library8.jpeg"
import Library9 from "../../assets/img/structure/library9.jpeg"



export default function Structure() {
    const headRef = useRef();

    return (
        <div>
            <PageHeader currentPage={"Campus Infrastructure"} travelPages={["About Us", "Infrastructure"]} />
            <div ref={headRef}></div>

            <div className="container-fluid mb-5 px-md-5 py-5 network">
                <div className="row justify-content-center">
                    <div className="col-md-9">
                        <h2 className="text-center">Campus Infrastructure</h2>
                        <iframe width="100%"
                            height="300"
                            className="mb-3 mt-2"
                            src="https://www.youtube.com/embed/Jg4BFe1QLVg"
                            title="Campus" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen></iframe>
                        <h2>Campus - Site Map</h2>
                        <img src={SiteMap} className="img-fluid mt-3 mb-3" />
                        <ul>
                            <li>The 55-acre campus is nestled at the foot of the Nagamalai hills.</li>
                            <li>The Academy is equipped with infrastructure facilities that match</li>
                            <li>International standards including classrooms, multi-purpose training hall areas,</li>
                            <li>Dining facilities and a library. The Academy has boarding and lodging facilities in its hostel (furnished single and double occupancy rooms) and guest house.</li>
                        </ul>
                        <h2>Vaigai Hall</h2>
                        <img src={VaigaiHall} className="img-fluid mt-3 mb-3" />
                        <ul>
                            <li>With Projector</li>
                            <li>Multipurpose Training hall</li>
                            <li>70 to 100 persons can be accommodated.</li>
                        </ul>
                        <h2>Ganga and Yamuna Hall</h2>
                        <ul>
                            <li>Classroom - Gallery type</li>
                            <li>With LCD screens</li>
                            <li>30 to 50 participants can be accommodated</li>
                        </ul>
                        <p>This availability is based on the presence of TDA students</p>
                        <h2>WKC Training Hall:</h2>
                        <ul>
                            <li>Meeting Hall</li>
                            <li>With LCD screen</li>
                            <li>Max 15 participants can be accommodated</li>
                        </ul>
                        <h2>Hostel Rooms</h2>
                        <ul>
                            <li>Total Rooms - 37 Rooms Available including gents and ladies hostel. (common bathroom only)</li>
                        </ul>
                        <p>This is also based on of TDA students, other TDA courses / DMP participants presence</p>
                        <h2>Guest Rooms</h2>
                        <p>8 rooms with attached bathroom available</p>
                        <h2>Sangam Library</h2>
                        <div className="row mt-3">
                            <div className="col-md-4 mb-3">
                                <img src={Library1} className="img-fluid" />
                            </div>
                            <div className="col-md-4 mb-3">
                                <img src={Library2} className="img-fluid" />
                            </div>
                            <div className="col-md-4 mb-3">
                                <img src={Library3} className="img-fluid" />
                            </div>
                            <div className="col-md-4 mb-3">
                                <img src={Library4} className="img-fluid" />
                            </div>
                            <div className="col-md-4 mb-3">
                                <img src={Library5} className="img-fluid" />
                            </div>
                            <div className="col-md-4 mb-3">
                                <img src={Library6} className="img-fluid" />
                            </div>
                            <div className="col-md-4 mb-3">
                                <img src={Library7} className="img-fluid" />
                            </div>
                            <div className="col-md-4 mb-3">
                                <img src={Library8} className="img-fluid" />
                            </div>
                            <div className="col-md-4 mb-3">
                                <img src={Library9} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
