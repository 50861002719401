import Repository from "./Repository";

class HomeRepository {
  constructor(callback) {
    this.callback = callback;
  }

  async getNews() {
    let url = `/api/news/getnewscount`;
    const response = await Repository.get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
    return response;
  }

  async getBannerSlides(type) {
    let url = `/api/home/getimages?type=${type}`;
    const response = await Repository.get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
    return response;
  }

  async getWayOfLife() {
    let url = `/api/home/getvideo`;
    const response = await Repository.get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
    return response;
  }
}

export default new HomeRepository();
