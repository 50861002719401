import { useState, useRef } from "react";
import Slider from "react-slick";

import PageHeader from "../components/PageHeader";
import Immersion1 from "../assets/img/immersion/immersion1.png"
import Immersion2 from "../assets/img/immersion/immersion2.png"
import Immersion3 from "../assets/img/immersion/immersion3.png"
import Immersion4 from "../assets/img/immersion/immersion4.png"
import Immersion5 from "../assets/img/immersion/immersion5.png"
import Immersion6 from "../assets/img/immersion/immersion6.png"
import Immersion7 from "../assets/img/immersion/immersion7.png"
import Immersion8 from "../assets/img/immersion/immersion8.png"
import RDM from "../assets/img/immersion/rd.png"
import RDM1 from "../assets/img/immersion/rd1.png"
import RDM2 from "../assets/img/immersion/rd2.png"
import RDM3 from "../assets/img/immersion/rd3.png"
import Water from "../assets/img/immersion/water.png"
import Water1 from "../assets/img/immersion/water1.png"
import Agri from "../assets/img/immersion/agri.png"
import Agri1 from "../assets/img/immersion/agri1.png"
import PP1 from "../assets/img/immersion/pp1.png"
import PP2 from "../assets/img/immersion/pp2.png"



export default function Immersion() {
    const headRef = useRef();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        padding: 5,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const [activeTab, setActiveTab] = useState(1);

    const changeTab = (index) => {
        setActiveTab(index);
        headRef.current.scrollIntoView();
    };

    return (
        <div>
            <PageHeader
                currentPage={" Immersion Programme"}
                travelPages={[
                    "Academics",
                    "Immersion Programme",
                ]}
            />
            <div className="container-fluid my-5 px-md-5">
                <div className="course-content">
                    <h1 className="text-center mb-5">Immersion Programme</h1>
                    <div className="row justify-content-center">
                        <div className="col-md-5 align-self-center">
                            <ul>
                                <li>
                                    <p> Immersion programmes are designed to educate and sensitize the participants about the Grassroot realities and experience the village way of life by living with the community
                                    </p>
                                </li>
                                <li>
                                    <p>  Create opportunities for the participants to connect “SELF” with the community and their way of life.    </p>
                                </li>
                                <li>
                                    <p> Main aim of the programmes is to bring attitudinal changes in the participants and  bring changes in the self and society.    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-4 py-3">
                                    <img src={Immersion1} className="img-fluid" />
                                </div>
                                <div className="col-md-4 align-self-center">
                                    <img src={Immersion6} className="img-fluid" />
                                </div>
                                <div className="col-md-4 py-3">
                                    <img src={Immersion3} className="img-fluid" />
                                </div>
                                <div className="col-md-4">
                                    <img src={Immersion4} className="img-fluid" />
                                </div>
                                <div className="col-md-4">
                                    <img src={Immersion5} className="img-fluid" />
                                </div>
                                <div className="col-md-4">
                                    <img src={Immersion2} className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row  justify-content-center mt-5">
                        <div className="col-md-5 align-self-center">
                            <img src={Immersion7} className="img-fluid" />
                        </div>
                        <div className="col-md-5 p-4 align-self-center">
                            <img src={Immersion8} className="img-fluid" />
                        </div>

                    </div>
                </div>
                <div ref={headRef}></div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="sticky-left">
                            <ul className="list-select">
                                <li
                                    className={activeTab == 1 ? "active" : ""}
                                    onClick={() => changeTab(1)}
                                >
                                    Rural Development Immersion Programme
                                </li>
                                <li
                                    className={activeTab == 2 ? "active" : ""}
                                    onClick={() => changeTab(2)}
                                >
                                    Development Immersion Programme
                                </li>
                                <li
                                    className={activeTab == 3 ? "active" : ""}
                                    onClick={() => changeTab(3)}
                                >
                                    Water Immersion Programme
                                </li>
                                <li
                                    className={activeTab == 4 ? "active" : ""}
                                    onClick={() => changeTab(4)}
                                >
                                    Agriculture Immersion Programme
                                </li>
                                <li
                                    className={activeTab == 5 ? "active" : ""}
                                    onClick={() => changeTab(5)}
                                >
                                    Participatory Learning Immersion Programme
                                </li>
                                <li
                                    className={activeTab == 6 ? "active" : ""}
                                    onClick={() => changeTab(6)}
                                >
                                    Costing
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-9">
                        {activeTab == 1 && (
                            <div className="course-content">
                                <h2>    Rural Development Immersion Programme – Focus (Type A)</h2>
                                <div className="row">
                                    <div className="col-md-7 align-self-center">
                                        <ol className="upper-roman">
                                            <li>
                                                <p>Rural finance and rural development, Poverty dimensions and Holistic village economy systems and management</p>
                                            </li>
                                            <li>
                                                <p>Participatory Learning tools and Community Engagement</p>
                                            </li>
                                            <li>
                                                <p>Community Led/Centric – Development Intervention models</p>
                                            </li>
                                            <li>
                                                <p> Village simulation exercise</p>
                                            </li>
                                            <li>
                                                <p>  <strong>  ‘Self’ </strong> – Exploration and increased understanding of their Attitude</p>
                                            </li>
                                        </ol>
                                        <p>  <strong> Module 1:  </strong>Social Capital, Poverty and Developmente</p>
                                        <p>  <strong> Module 2:  </strong>Agriculture Developmente</p>
                                        <p>  <strong>  Module 3: </strong> Livelihoods and Rural Developmente</p>
                                        <p>  <strong> Module 4: </strong> Health and Social Securitye</p>
                                    </div>
                                    <div className="col-md-5">
                                        <img src={RDM} className="img-fluid" />
                                    </div>
                                </div>
                                <table className="table-bordered mt-5">
                                    <thead>
                                        <tr>
                                            <th>Week</th>
                                            <th>Focus</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>
                                                <p>Module 1: Social Capital, Poverty and Development</p>
                                                <ul>
                                                    <li>Social capital – Self Help Groups, Farmer associations and its impact</li>
                                                    <li> Understanding Village level institution</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>
                                                <p>Module 2: Agriculture Development </p>
                                                <ul>
                                                    <li>Agricultural Development and Marketing (Backward and Forward Linkages) </li>
                                                    <li> Agriculture and Irrigation system in different context, Tank-fed and Ahar-Pyne traditional irrigation systems</li>
                                                    <li>ICT And Agriculture</li>
                                                    <li>Social enterprises – Farmers’ Producers Organization </li>
                                                </ul>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>
                                                <p>Module 3: Livelihoods and Rural Development</p>
                                                <ul>
                                                    <li>Livelihood frameworks and models </li>
                                                    <li> Non farm livelihoods</li>
                                                    <li>Cash flow analysis
                                                    </li>
                                                    <li>Rural support, entitlements
                                                    </li>
                                                </ul>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>
                                                <p>Module 4: Health and Social Security</p>
                                                <ul>
                                                    <li>Mutual Insurance and impact</li>
                                                    <li>Community health and Community hospital models </li>
                                                    <li>Presentation on the learnings and Panel discussion </li>
                                                    <li>Exploration of ‘Self’   </li>
                                                    <li>Valedictory</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>Each Module will have two / three days Field visit, village stay and interaction with different stakeholders.</p>

                                <h2 className="mt-5"> Rural Development Immersion Programme – Focus (Type B)</h2>
                                <div className="row mt-3">
                                    <div className="col-md-7 align-self-center">
                                        <ol className="upper-roman">
                                            <li>
                                                <p>Rural finance and rural development, Poverty dimensions and Holistic village economy systems and management  </p>
                                            </li>
                                            <li>
                                                <p>Participatory Learning tools and Community Engagement </p>
                                            </li>
                                            <li>
                                                <p>Community Led/Centric – Development Intervention models  </p>
                                            </li>
                                            <li>
                                                <p>Village simulation exercise</p>
                                            </li>
                                            <li>
                                                <p><strong> Village stay:</strong>
                                                    Demography, Natural resources and patterns of change<br />
                                                    Livelihood Pattern, Challenges<br />
                                                    Services, Infrastructure and Institutions<br />
                                                    Savings, Credit, Finance, Relationships and Transactions<br />
                                                    Socially disadvantaged communities and their life<br />
                                                    Health and Sanitation<br />
                                                    Social Security, Government Entitlements and its impact on poor community
                                                </p>
                                            </li>
                                            <li>
                                                <p>  <strong>  ‘Self’ </strong> – Exploration and increased understanding of their Attitude</p>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="col-md-5">
                                        <img src={RDM1} className="img-fluid" />
                                    </div>
                                </div>
                                <table className="table-bordered mt-2">
                                    <thead>
                                        <tr>
                                            <th>Day</th>
                                            <th>Focus</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>
                                                <p>Inaugural Session
                                                    Understanding Poverty, Microfinance and Financial literacy
                                                    Journey of community leaders from moving out of poverty (Group activity and interaction)
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>
                                                <p>Visit to Financial Literacy interventions, Community radio, FPO, organic farmer, and other successful and unique community models
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>
                                                <p>Village Simulation Exercise</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>
                                                <p>Demo and Participatory research and Community engagement</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5-7</td>
                                            <td>
                                                <p>Village stay (3 days)</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td>
                                                <p>Holiday and presentation preparation </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>9</td>
                                            <td>
                                                <p>Presentation on the learnings and Panel discussion </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>10  </td>
                                            <td>
                                                <p>Exploration of ‘Self’ Valedictory </p>
                                            </td>
                                        </tr>




                                    </tbody>
                                </table>
                            </div>)}
                        {activeTab == 2 && (
                            <div className="course-content">
                                <h2 className="mt-5">Development Immersion Programme</h2>
                                <div className="row mt-3">
                                    <div className="col-md-7 align-self-center">
                                        <ol className="upper-roman">
                                            <li>
                                                <p>Rural finance and rural development, Poverty dimensions and Holistic village economy systems and management   </p>
                                            </li>
                                            <li>
                                                <p>Participatory Learning tools and Community Engagement </p>
                                            </li>
                                            <li>
                                                <p>Community Led/Centric – Development Intervention models </p>
                                            </li>
                                            <li>
                                                <p>Village simulation exercise</p>
                                            </li>
                                            <li>
                                                <p> <strong> Village stay:</strong>
                                                    Demography, Natural resources and patterns of change<br />
                                                    Livelihood Pattern, Challenges<br />
                                                    Services, Infrastructure and Institutions<br />
                                                    Savings, Credit, Finance, Relationships and Transactions<br />
                                                    Socially disadvantaged communities and their life<br />
                                                    Health and Sanitation<br />
                                                    Social Security, Government Entitlements and its impact on poor community
                                                </p>
                                            </li>
                                            <li>
                                                <p>  <strong>  ‘Self’ </strong> – Exploration and increased understanding of their Attitude</p>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="col-md-5">
                                        <img src={RDM2} className="img-fluid mb-3" />
                                        <img src={RDM3} className="img-fluid" />
                                    </div>
                                </div>
                                <table className="table-bordered mt-2">
                                    <thead>
                                        <tr>
                                            <th>Day</th>
                                            <th>Focus</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>
                                                <p>Inauguration
                                                    Lectures on ‘Understand poverty and its dimensions’
                                                    Exploration of ‘Self’

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>
                                                <p>Interaction with community Leaders
                                                    Demo session on Participatory research and Community engagement  </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>
                                                <p>Village Simulation Exercise</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>
                                                <p>Field visits to unique community models and successful farmers. Other NGO organisations </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5-7</td>
                                            <td>
                                                <p>Village stay (3 days)</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td>
                                                <p>Holiday and presentation preparation </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>9</td>
                                            <td>
                                                <p>Presentation on the learnings and Panel discussion </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>10  </td>
                                            <td>
                                                <p>Valedictory </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>)}
                        {activeTab == 3 && (
                            <div className="course-content">
                                <h2 className="mt-5">Water Immersion Programme</h2>
                                <div className="row mt-3">
                                    <div className="col-md-7 align-self-center">
                                        <ol className="upper-roman">
                                            <li>
                                                <p>Water Immersion Programme (WIP) aims to broaden and deepen their knowledge in the water sector and increase awareness about the sustainable use of the precious water resources and the whole programme is taught by practitioners and experts who are presently working in the ﬁeld.</p>
                                            </li>
                                            <li>
                                                <p>It also provide hands on experience in Traditional Water Management systems, Water Treatment Technologies Surveying and Planning using advanced survey equipments, etc.</p>
                                            </li>
                                            <li>
                                                <p>This is one of kind programme to  strengthen the capacity and Knowledge of students/professionals in water and environment sector..  </p>
                                            </li>
                                            <li>
                                                <p>Mainly focused on preparing estimate, surveying and using Total station, GPS and GIS taught by the practitioners in the ﬁeld. Other broader areas covered are Dam Engineering basics, Watershed Planning and Management with ﬁled visits, Heritage visits to Traditional water management systems</p>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="col-md-5">
                                        <img src={Water} className="img-fluid mb-3" />
                                        <img src={Water1} className="img-fluid" />
                                    </div>
                                </div>
                                <table className="table-bordered mt-2">
                                    <thead>
                                        <tr>
                                            <th>Day</th>
                                            <th>Focus</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>
                                                <p>Water Basics and Introduction to Tank system and DHAN’s Tank Programme</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>
                                                <p>Visit to Decentralised waste water treatment systems and Roof water harvesting systems</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>
                                                <p>Introduction to Storm water management and Watershed management</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>
                                                <p>Understanding Traditional water harvesting systems along with Heritage Visit to Tanks & Anicut structures and Field visit </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5-9</td>
                                            <td>
                                                <p>Field Survey and mapping in groups using GPS and Total Station (Practical Session)
                                                    Preparing Draft estimates and maps
                                                    Report Preparation
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>10  </td>
                                            <td>
                                                <p>Presentation & Concluding Session </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>)}
                        {activeTab == 4 && (
                            <div className="course-content">
                                <h2 className="mt-5">Agriculture Immersion Programme </h2>
                                <div className="row mt-3">
                                    <div className="col-md-7 align-self-center">
                                        <ol className="upper-roman">
                                            <li>
                                                <p> <strong> Poverty Dimension </strong>  and the contribution of Agricultural sector to reduce poverty with sustainable interventions
                                                </p>
                                            </li>
                                            <li>
                                                <p>Basic undertaking of our <strong> traditional wisdom </strong> on water harvesting and irrigation systems – Tank-fed irrigation systems
                                                </p>
                                            </li>
                                            <li><p>Village simulation exercise
                                            </p></li>
                                            <li>
                                                <p>  <strong> Village stay:</strong>
                                                    Rain-fed and Canal-fed Agriculture systems, Dryland Horticulture and Small Millets reach, Practice and viability of Organic farming<br />
                                                    Nexus between Agriculture and Livestock development,<br />
                                                    Farmers’ Federations, Farmers’ Producers Organization and the role of agriculture graduates.<br />
                                                    Farmers daily routine, cash flow, market linkages, production systems, etc<br />
                                                    Government entitlements, bank loans, subsidies and its impact<br />
                                                </p>
                                            </li>
                                            <li>
                                                <p>  <strong>  ‘Self’ </strong> – Exploration and increased understanding of their Attitude</p>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="col-md-5">
                                        <img src={Agri} className="img-fluid mb-3" />
                                        <img src={Agri1} className="img-fluid" />
                                    </div>
                                </div>
                                <table className="table-bordered mt-2">
                                    <thead>
                                        <tr>
                                            <th>Day</th>
                                            <th>Focus</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>
                                                <p>Inauguration
                                                    Lectures on ‘Understand poverty and Agriculture’
                                                    Field Visits and session on ‘Traditional Tank-fed irrigation systems’
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>
                                                <p>Village Simulation Exercise </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>
                                                <p>Demo session on Participatory research and Community engagement </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>
                                                <p>Field visits to unique community models and successful farmers</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5-7</td>
                                            <td>
                                                <p>Village stay (3 days)
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td>
                                                <p>Holiday and presentation preparation</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>9</td>
                                            <td>
                                                <p>Presentation on the learnings and Panel discussion </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>10</td>
                                            <td>
                                                <p>Exploration of ‘Self’
                                                    Valedictory </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>)}
                        {activeTab == 5 && (
                            <div className="course-content">
                                <h2 className="mt-5">Participatory Learning Immersion Programme </h2>
                                <div className="row mt-3">
                                    <div className="col-md-7 align-self-center">
                                        <p> This programme focusses on providing hands on training programme which deals with planning and designing using Participatory Learning Methods (PALM). PALM helps to understand the local resources, how the architecture, basic infrastructure evolved and also their social, economical and cultural development over the years.
                                        </p>
                                        <p>This method will help in assessing their needs, available resources and how those can be utilized for their own development with community participation.
                                        </p>
                                        <ol className="upper-roman">
                                            <li>
                                                <p>Understanding about usefulness of the PALM techniques used planning
                                                </p>
                                            </li>
                                            <li>
                                                <p>  Hands on experience in PALM techniques
                                                </p>
                                            </li>
                                            <li>
                                                <p>  Exposure to Rural village, their needs and requirements
                                                </p>
                                            </li>
                                            <li>
                                                <p>Need based and participatory planning and design
                                                </p>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="col-md-5">
                                        <img src={PP1} className="img-fluid mb-3" />
                                        <img src={PP2} className="img-fluid" />
                                    </div>
                                </div>
                                <table className="table-bordered mt-2">
                                    <thead>
                                        <tr>
                                            <th>Day</th>
                                            <th>Focus</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>
                                                <p>Inauguration
                                                    Lecture and Demo session on “Participatory Tools and Community Engagement’

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>
                                                <p>Field Visit to Villages and doing ‘Participatory research tools’
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>
                                                <p>Participatory Rural planning and design approach (Participant presentation and discussion)
                                                    Valedictory
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {activeTab == 6 && (
                            <div className="course-content">
                                <h2>Costing</h2>
                                <ul>
                                    <li> <p>Costing will differs based on
                                        number of days, Online/offline, On-campus/off-campus, Menu and room occupancy ,Student / institution payment </p></li>
                                    <li><p>Costing can be customized based on the institution, programme and requirements
                                    </p></li>
                                    <li><p>Costing shown here is indicative only
                                    </p></li>
                                    <li><p>Payments will have additional of 18 % GST
                                    </p></li>
                                </ul>
                                <div className="row my-4">
                                    <div className="col-md-6">
                                        <h5>Off Campus: Lecture </h5>
                                        <ul>
                                            <li><p>
                                                Student: Rs. 1000 per student per day
                                            </p></li>
                                            <li><p>Working professionals: Rs. 1200 participant per day         </p>                 </li>
                                        </ul>
                                        <h5>On Campus: Participant based Payment Mode</h5>
                                        <ul>
                                            <li><p>Resource fee: Rs. 25000 per day  </p></li>
                                            <li><p>Boarding 200 - 300 / Person / Day (Changes based on the menu requirement) </p></li>
                                            <li><p>Lodging: Rs. 250 /person / day (Double occupancy) . Rs. 350 (Single occupancy) </p> </li>
                                            <li><p>Village stay: Rs. 1500 per village per day </p></li>
                                            <li><p>Other costs Rs. 7500 per day (hall rent, admin, labour and miscellaneous expenses) </p></li>
                                            <li><p>Resource kit: Rs 750 per participant </p></li>
                                            <li><p>Travel as per actuals </p></li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6 align-self-center">
                                        <h5>Off Campus: Full programme</h5>
                                        <ul>
                                            <li><p>Resource fee: Rs. 25000 per day  </p></li>
                                            <li><p>Village stay: Rs. 1500 per village (per per day)</p></li>
                                            <li><p>
                                                Resource kit: Rs 750 per participant</p>
                                            </li>
                                            <li><p>Travel as per actuals</p>
                                            </li>
                                        </ul>
                                        <h5>Off Campus: Lecture </h5>
                                        <ul>
                                            <li><p>
                                                Resource Fee (offline): Rs. 2500 per session (2 hrs) + Travel</p>
                                            </li>
                                            <li><p>Resource Fee (online): Rs. 2000 per session</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="course-content">
                    <h2 className="mt-5">Testimonials</h2>
                    <Slider {...settings}>
                        <div>
                            <iframe width="95%"
                                height="300"
                                className="mb-3 mt-3"
                                src="https://www.youtube.com/embed/gjZL1Y5pnO4?list=PLuXRu7SK08yt6JZZeL1zh6LgdE9E4RWvM"
                                title="RDIP - Experience Sharing by Ashutosh Roy"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                        </div>
                        <div>
                            <iframe width="95%"
                                height="300"
                                className="mb-3 mt-3" src="https://www.youtube.com/embed/i0IkzdF3pJc?list=PLuXRu7SK08yt6JZZeL1zh6LgdE9E4RWvM" title="RDIP - Experience Sharing by Amala S Maheswari" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <div>
                            <iframe width="95%"
                                height="300"
                                className="mb-3 mt-3" src="https://www.youtube.com/embed/tklhOTTx_A8?list=PLuXRu7SK08yt6JZZeL1zh6LgdE9E4RWvM" title="Development Immersion Programme for Management Trainees of Aditya Brila Capital Limited" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <div>
                            <iframe width="95%"
                                height="300"
                                className="mb-3 mt-3" src="https://www.youtube.com/embed/Aue5zTHAQ80?list=PLuXRu7SK08yt6JZZeL1zh6LgdE9E4RWvM" title="Gesina Yomso, Aditya Birla Sun Life Insurance" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <div>
                            <iframe width="95%"
                                height="300"
                                className="mb-3 mt-3" src="https://www.youtube.com/embed/nma1T8_eblQ?list=PLuXRu7SK08yt6JZZeL1zh6LgdE9E4RWvM" title="Nikhil Jagtap, Aditya Birla Finance Limited" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <div>
                            <iframe width="95%"
                                height="300"
                                className="mb-3 mt-3" src="https://www.youtube.com/embed/gKmsbcprotQ?list=PLuXRu7SK08yt6JZZeL1zh6LgdE9E4RWvM" title="Vibhor Jaunsari, Aditia Birla Finance Limited" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <div>
                            <iframe width="95%"
                                height="300"
                                className="mb-3 mt-3" src="https://www.youtube.com/embed/ILFq2s8Mr1U?list=PLuXRu7SK08yt6JZZeL1zh6LgdE9E4RWvM" title="Shuchi Goel, Aditya Birla Sun Life Insurance Limited" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <div>
                            <iframe width="95%"
                                height="300"
                                className="mb-3 mt-3" src="https://www.youtube.com/embed/o0_2ZrY6ANk?list=PLuXRu7SK08yt6JZZeL1zh6LgdE9E4RWvM" title="Sarthak Singh, Aditya Birla Sun Life Insurance Limited" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <div>
                            <iframe width="95%"
                                height="300"
                                className="mb-3 mt-3" src="https://www.youtube.com/embed/DL2q9TYCCy8?list=PLuXRu7SK08yt6JZZeL1zh6LgdE9E4RWvM" title="Prakrati Sharma, Aditya Birla Sun Life Insurance" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <div>
                            <iframe width="95%"
                                height="300"
                                className="mb-3 mt-3" src="https://www.youtube.com/embed/1Zc-C-nnAO8?list=PLuXRu7SK08yt6JZZeL1zh6LgdE9E4RWvM" title="Pratik Chaudhary, Aditya Birla Sun Life Insurance" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </Slider>
                </div>
            </div>
        </div >
    );
}
