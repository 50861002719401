import { useState, useRef } from "react";
import Slider from "react-slick";

import PageHeader from "../components/PageHeader";
import { Link } from 'react-router-dom';



export default function ExEducation() {
    const headRef = useRef();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        padding: 5,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const [activeTab, setActiveTab] = useState(1);

    const changeTab = (index) => {
        setActiveTab(index);
        headRef.current.scrollIntoView();
    };

    return (
        <div>
            <PageHeader
                currentPage={" Executive Education"}
                travelPages={[
                    "Academics",
                    "Executive Education",
                ]}
            />
            <div className="container-fluid my-5 px-md-5">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="course-content">
                            <h1 className="text-center mb-5">Executive Education</h1>
                            <h2 className="mt-5">Short term Programmes (below 6 months)</h2>
                            <ul>
                                <li>
                                    <p>Certificate Programmes for Farmer Producer Organisation (3 months) (Learn More) </p>
                                </li>
                                <li>
                                    <p>Advanced Reflective Training programme (3 to 5 days) (Learn More)</p>
                                </li>
                                <li>
                                    <p>Future search programme and Vision building (3 days) </p>
                                </li>
                            </ul>
                            <p>Other Key Short Term Programmes are </p>
                            <ul>
                                <li>
                                    <p>Faculty Development Programmes (3 days programme)</p>
                                </li>
                                <li>
                                    <p>Leadership Programmes (3 to 5 days)</p>
                                </li>
                                <li>
                                    <p>Strategic Management (3 days) </p>
                                </li>
                            </ul>
                            <p>Based on the need, the customized programmes are organized. For further details, email us at tda@dhan.org</p>
                            <h2>Development Management Programmes (DMP) – (3 to 6 days programmes)</h2>
                            <p>Development Management programmes are designed for the working professionals to improve their skills
                                and knowledge on various themes and sectors. These are designed specifically based on the needs and demands of the
                                participants and the institutions. Some of the Key DMPs offered by the academy are as follows</p>
                            <ul>DMPs on
                                <li>
                                    <p>Team building</p>
                                </li>
                                <li>
                                    <p>Social capital promotion (Building people institutions)</p>
                                </li>
                                <li>
                                    <p>Microfinance for poor </p>
                                </li>
                                <li>
                                    <p>Micro insurance for poor </p>
                                </li>
                                <li>
                                    <p>Accounting and Management </p>
                                </li>
                                <li>
                                    <p>Technical training on Irrigation tank management and Surveying</p>
                                </li>
                                <li>
                                    <p>Re COPE – Resource Mobilization through Competitive Proposal Ecosystem</p>
                                </li>
                                <li>
                                    <p>First Aid and Rescue </p>
                                </li>
                                <li>
                                    <p>Credit, MIS, Monitoring and Evaluation </p>
                                </li>
                                <li>
                                    <p>Federation In charges and coordinators – Federation Managements and Systems Strengthening </p>
                                </li>
                                <li>
                                    <p>Livelihood Promotion </p>
                                </li>
                                <li>
                                    <p>Basics of Banking System and procedures in context of SHG Linkages </p>
                                </li>
                                <li>
                                    <p>Managerial Oral Communication </p>
                                </li>
                                <li>
                                    <p>Audio and Visual communication</p>
                                </li>
                                <li>
                                    <p>Farmer Producer organisations (FPO) </p>
                                </li>
                            </ul>
                            <h2>Advanced Reflective Training programme (3 to 5 days)</h2>
                            <p>Microﬁnance programmes in India are basically of two distinct intervention models/approaches-the enabling model of SHG bank linkage programme
                                and the delivery model through for proﬁt microﬁnance institutions. e impressive and fast paced growth recorded by microﬁnance programmes over
                                the past three decades has resulted in their facing a number of challenges including availability of competent and passionate human resources.
                                Despite advancement of technology the dependence of microﬁnance on good human reasons is due to the need for professional relationship and yet
                                close contact with group members. Today there is a greater recognition in the sector on the need to invest in the capacity and capabilities building
                                of NGOs and MFIs in terms of knowledge, skill and attitude required to manage the growth. In the above background, The DHAN Academy (TDA) has designed
                                an exclusive Advanced Diploma in Microﬁnance (ADMF) aimed at building capacity in MFI sector. </p>

                            <h2>Advanced Diploma in Microﬁnance is ideal for Executives, Managers, Credit oﬃcers, Practitioners, Bank employees, Government agencies, NGOs and Other institutions who work with Micro ﬁnance sector.</h2>
                            <h6>Course objectives</h6>
                            <p>The course aims at enabling the participants</p>
                            <ul>
                                <li>
                                    <p>To learn from the ﬁeld practice of integrating microﬁnance and development</p>
                                </li>
                                <li>
                                    <p>To develop knowledge on strategic skill and operational tools needed to design and manage the process of microﬁnance</p>
                                </li>
                                <li>
                                    <p>To develop knowledge on strategic skill and operational tools needed to design and manage the process of microﬁnance</p>
                                </li>
                            </ul>
                            <p style={{ lineHeight: 2 }}>  The course covers all models of microﬁnance and is structured in three modules namely<br />
                                1. Microﬁnance Basics<br />
                                2. Microﬁnance operations and<br />
                                3. Microﬁnance Management<br />
                            </p>
                            <h2>Certificate Programmes for Farmer Producer Organisation (3 months)</h2>
                            <h6 className="mt-0">Overview</h6>
                            <p>Evident from the initiatives of National Bank of Agriculture and Rural Development (NABARD) and Small Farmers Agri-business Consortium (SFAC), thousands of Farmers’ Producers Organisations get promoted and registered as a legal entity under special provision of Companies of Act, 1956, governed by Board of Governors represented from the farmers (Producers) and managed by Chief Executive. While mapping the capacity enhancement and skill development requirement for making the Farmers’ Producers Organisation, a viable initiative, systematic training and grooming the Chief Executive from basics, operational aspects, managerial skill development, legal compliances, a strong felt need emerged from NABARD and Bankers Institute of Rural Development, Lucknow.  In continuation to few consultative deliberations, Tata-DHAN Academy, a pioneer development management school in Madurai has consented to partner with them in offering three months certificate course with a mix of class room and field based learning modules for the FPOs being operational and/or being set up in Tamil Nadu and Kerala State. The Certificate Programme is designed to full fill the following objectives:</p>
                            <ul>
                                <li><p>To build the conceptual clarity about the institutional aspects and mandatory functions of FPO  for the respective CEOs;</p></li>
                                <li><p> To train the CEO and impart the required skills such as Participatory Planning, Data analysis, Accounting and Auditing, Filing the IT returns as required;</p></li>
                                <li><p> To develop right attitude to the CEOs in order to develop their respective FPO as a successful and profitable Social Business enterprises.</p></li>
                            </ul>

                            <p>For full design / custamisation / dates / fess,kindly write to us at tda@dhan.org</p>
                        </div>
                        <div className="text-center d-flex">
                            <Link className="outline-btn" target="_blank" to="https://thedhanacademy.wordpress.com/updates/">Updates</Link>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    );
}
