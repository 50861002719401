import { useState, useEffect } from "react";

import HomeRepository from "../repositories/HomeRepository";

export default function Marquee() {
  const [marquee, setMarquee] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let res = await HomeRepository.getNews();
    let data = [];
    res?.data?.length > 0 &&
      res?.data.map((d, i) => {
        data.push({
          link: d.link,
          description: d?.description,
        });
      });
    setMarquee(data);
  };

  return (
    <marquee
      className="mt-3"
      direction="left"
      behavior="scroll"
    // onMouseOver="this.stop();"
    // onMouseOut="this.start();"
    >
      <p>
        {marquee.length > 0 &&
          // marquee.map((m, i) => {
          //   return (
          <b className="me-5 pe-5">
            <a href={marquee[0]?.link} target="_blank" style={{ color: "red" }}>
              {marquee[0]?.description}
            </a>
          </b>
          // );  })
        }
      </p>
    </marquee>
  );
}
