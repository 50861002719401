import React from "react";

import Picture1 from "../assets/img/structure/Picture1.jpg";
import Picture2 from "../assets/img/structure/Picture2.jpg";
import Picture3 from "../assets/img/structure/Picture3.jpg";
import Picture4 from "../assets/img/structure/Picture4.jpg";
import Picture5 from "../assets/img/structure/Picture5.jpg";

export default function Structure() {
  return (
    <div className="course-content">
      <div className="row">
        <div className="col-md-6">
          <div className="d-flex w-100 h-100 align-items-center justify-content-center">
            <h2>Academic Journey</h2>
          </div>
        </div>
        <div className="col-md-6">
          <img src={Picture1} className="img-fluid" alt="" />
        </div>
        <div className="col-md-12">
          <img src={Picture2} className="img-fluid" alt="" />
        </div>
        <h2 className="mt-5 mb-4 text-center">Segments</h2>
        <div className="col-md-5 h-100">
          <div className="w-75 mb-4">
            <img src={Picture3} className="img-fluid" alt="" />
          </div>
          <div className="w-75 ms-auto">
            <img src={Picture4} className="img-fluid" alt="" />
          </div>
        </div>
        <div className="col-md-7 h-100">
          <img src={Picture5} className="img-fluid" alt="" />
        </div>
        <h2 className="mt-5 mb-4 text-center">Disciplines and Term Focus</h2>
        <div className="col-md-5">
          <div className="row justify-content-center">
            <div className="col-6 mb-3">
              <div className="text-card">
                <p>Basics of Development</p>
              </div>
            </div>
            <div className="col-6 mb-3">
              <div className="text-card">
                <p>Technology for Development</p>
              </div>
            </div>
            <div className="col-6 mb-3">
              <div className="text-card">
                <p>Communication for Development</p>
              </div>
            </div>
            <div className="col-6 mb-3">
              <div className="text-card">
                <p>Management for Development</p>
              </div>
            </div>
            <div className="col-6 mb-3">
              <div className="text-card">
                <p>Leadership and Institution Building</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <table className="custom-table">
            <thead>
              <tr>
                <th>Term</th>
                <th>Classroom Segment</th>
                <th>Field Segment</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>I</td>
                <td>Basics and Self</td>
                <td>Village Study</td>
              </tr>
              <tr>
                <td>II</td>
                <td>Development Intervention Models</td>
                <td>Research study</td>
              </tr>
              <tr>
                <td>III</td>
                <td>Management and Community</td>
                <td>Organizing Community</td>
              </tr>
              <tr>
                <td>IV</td>
                <td>Management and Policies</td>
                <td>Management</td>
              </tr>
              <tr>
                <td>V</td>
                <td>Institution Building and Leadership</td>
                <td>NGOs and Community Leaders Visit</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
